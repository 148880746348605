import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ModalState = {
  showDigitalOptometryModal: boolean;
  showExitModal: boolean;
  showInsuranceErrorPopup: boolean;
  showPrescriptionModal: boolean;
  showPrescriptionModalInfo: boolean;
  showPrescriptionModalPD: boolean;
  showPrescriptionModalContinue: boolean;
  showAddMyDoctorModal: boolean;
  showPrescriptionUploadFilePreviewModal: boolean;
  showPrescriptionReviewSeeDetails: boolean;
  isDoctorSelected: boolean;
  urlPrescriptionModal: string;
  openPrescriptionInputModal: boolean;
  learnMoreIds?: string[];
  learnMoreFocus?: string;
  showPaymentInstallmentsModal?: boolean;
  showYourSelectionsModal?: boolean;
  showCallMyDoctorCalifornianModal?: boolean;
  previousSearchResult?: any;
  doctorSelected?: any;
  showMissingPrescriptionsValue?: boolean;
  missingPrescriptionsValueResult?: boolean;
  showConfigError?: boolean;
  showMissingReadingAddValueModal?: boolean;
};

const initialState: ModalState = {
  showDigitalOptometryModal: false,
  showExitModal: false,
  showInsuranceErrorPopup: false,
  showPrescriptionModal: false,
  showPrescriptionModalInfo: false,
  showPrescriptionModalPD: false,
  showPrescriptionModalContinue: false,
  showAddMyDoctorModal: false,
  showPrescriptionUploadFilePreviewModal: false,
  showPrescriptionReviewSeeDetails: false,
  isDoctorSelected: false,
  urlPrescriptionModal: null,
  openPrescriptionInputModal: false,
  showPaymentInstallmentsModal: false,
  learnMoreFocus: null,
  showYourSelectionsModal: false,
  showCallMyDoctorCalifornianModal: false,
  previousSearchResult: null,
  doctorSelected: null,
  showMissingPrescriptionsValue: false,
  missingPrescriptionsValueResult: false,
  showConfigError: false,
  showMissingReadingAddValueModal: false,
};

const modalsSlice = createSlice({
  name: "modals",
  initialState: initialState,
  reducers: {
    setShowExitModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showExitModal = payload;
    },
    setShowInsuranceErrorPopup: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showInsuranceErrorPopup = payload;
    },
    setPrescriptionModal: (
      state,
      { payload }: PayloadAction<{ show: boolean; url?: string }>
    ) => {
      state.showPrescriptionModal = payload.show;
      if (payload.show) {
        state.urlPrescriptionModal = payload.url;
      } else {
        state.urlPrescriptionModal = null;
      }
    },
    setShowPrescriptionModalInfo: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showPrescriptionModalInfo = payload;
    },
    setShowPrescriptionModalPD: (
      state,
      { payload }: PayloadAction<{ show: boolean; modal: boolean }>
    ) => {
      payload.modal
        ? (state.showDigitalOptometryModal = payload.show)
        : (state.showPrescriptionModalPD = payload.show);
    },
    setShowPrescriptionModalContinue: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showPrescriptionModalContinue = payload;
    },
    setShowPrescriptionUploadFilePreviewModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showPrescriptionUploadFilePreviewModal = payload;
    },
    setShowAddMyDoctorModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showAddMyDoctorModal = payload;
    },
    setIsDoctorSelected: (state, { payload }: PayloadAction<boolean>) => {
      state.isDoctorSelected = payload;
    },
    setShowPrescriptionReviewSeeDetails: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showPrescriptionReviewSeeDetails = payload;
    },
    setOpenPrescriptionInputModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.openPrescriptionInputModal = payload;
    },
    setOpenLearnMoreModal: (state, { payload }: PayloadAction<string>) => {
      state.learnMoreFocus = payload;
    },
    setListLearnMoreModal: (state, { payload }: PayloadAction<string[]>) => {
      state.learnMoreIds = payload;
    },
    setShowPaymentInstallmentsModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showPaymentInstallmentsModal = payload;
    },
    setShowYourSelectionsModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showYourSelectionsModal = payload;
    },
    setShowCallMyDoctorCalifornianModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showCallMyDoctorCalifornianModal = payload;
    },
    setPreviousSearchResultState: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.previousSearchResult = payload;
    },
    setDoctorSelected: (state, { payload }: PayloadAction<any>) => {
      state.doctorSelected = payload;
    },
    setShowMissingPrescriptionsValue: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.showMissingPrescriptionsValue = payload;
    },
    setMissingPrescriptionsValueResult: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.missingPrescriptionsValueResult = payload;
    },
    setConfigError: (state, { payload }: PayloadAction<any>) => {
      state.showConfigError = payload;
    },
    setShowMissingReadingAddValueModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showMissingReadingAddValueModal = payload;
    },

    reset: (state, { payload }: PayloadAction<string>) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const modalsReducer = modalsSlice.reducer;
export const modalsActions = modalsSlice.actions;
