import { isFunction } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import { BrandComponent } from "../common/BrandComponent";
import { TranslateText } from "../common/TranslateText";
import "./default.module.scss";
import {
  useCurrentPackages,
  useEnableBrandLastStep,
  useGetDiscount,
} from "@hooks";

interface StepInReviewProps {
  title: string;
  selection: string[];
  strikePrice?: number;
  offerPrice?: number;
  insPrice?: number;
  included?: boolean;
  onEditClick: (title: string) => void;
  showSeeDetails?: boolean;
  hideMainPrice?: boolean;
  hideEdit?: boolean;
}

/**
 * Component that displays summary information about a step in the review page. It
 * accepts an input of type ```StepInReviewProps``` with the following parameters:
 *  - ```title```: the title of the step
 *  - ```selection```: an array of strings containing the selected values for that
 * step
 *  - ```onEditClick```: the callback function executed when clicking on edit step
 *  - ```strikePrice``` (_optional_): the full price of that step
 *  - ```offerPrice``` (_optional_): the discounted price of that step
 *  - ```included``` (_optional_): a flag that will display the included badge
 */
export function StepInReview({
  title,
  selection,
  strikePrice,
  offerPrice,
  insPrice,
  included,
  onEditClick,
  showSeeDetails,
  hideMainPrice,
  hideEdit,
}: StepInReviewProps) {
  const reduxDispatch = useDispatch();
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );

  const currentPackages = useCurrentPackages();
  const { someIsLoading } = useGetDiscount();
  const brandLastStep = useEnableBrandLastStep();
  const isNonPrescriptionAvailable = () => {
    return (
      !currentPackages ||
      currentPackages.find((pkg) => pkg.lensPackage.type === "Non_prescription")
    );
  };

  return (
    <div className={"StepInReview__mainContainer"}>
      <div className={"StepInReview__mainContainer__contentContainer"}>
        <div className="StepInReview__mainContainer__contentContainer__titleWrapper">
          <div className="StepInReview__mainContainer__contentContainer__title_container">
            <span
              className={
                "StepInReview__mainContainer__contentContainer__title_container__title"
              }
            >
              {(title === "color" || title === "lensColor") &&
              !brandLastStep ? (
                `${useTranslate(`steps.brand.confirmedTitle`).slice(
                  0,
                  -1
                )} & ${useTranslate(
                  `steps.${title}.confirmedTitle`
                ).toLowerCase()}`
              ) : (
                <TranslateText label={`steps.${title}.confirmedTitle`} />
              )}
            </span>
            <div
              className={
                "StepInReview__mainContainer__contentContainer__title_container__editContainer"
              }
            >
              {onEditClick && (
                <span
                  tabIndex={0}
                  className={
                    "StepInReview__mainContainer__contentContainer__title_container__editContainer__editBtn"
                  }
                  onClick={() => {
                    if (isFunction(onEditClick)) onEditClick(title);
                  }}
                >
                  {!hideEdit && <TranslateText label="editButton" />}
                </span>
              )}
            </div>
          </div>
          {((insuranceEnabled && insPrice !== undefined) ||
            !insuranceEnabled ||
            isNonPrescriptionAvailable()) && (
            <div className={"StepInReview__mainContainer__priceContainer"}>
              {!insPrice && (
                <>
                  {strikePrice !== null &&
                    strikePrice !== undefined &&
                    strikePrice !== 0 &&
                    !hideMainPrice && (
                      <BrandComponent
                        componentName="StepPrice"
                        parameter={{
                          price: strikePrice,
                          discount: strikePrice - offerPrice,
                          insPrice: insPrice,
                          hideSign: true,
                          priceStyle: { marginLeft: 4 },
                          ignorePriceLabels: true,
                          ignoreInsurance: isNonPrescriptionAvailable(),
                          discountLoading: someIsLoading(),
                        }}
                      />
                    )}
                  {(included || (offerPrice === 0 && strikePrice === 0)) &&
                    !hideMainPrice && (
                      <div
                        className={
                          "StepInReview__mainContainer__includedContainer"
                        }
                      >
                        <span
                          className={
                            "StepInReview__mainContainer__includedContainer__label"
                          }
                        >
                          <TranslateText label={"price.included"} />
                        </span>
                      </div>
                    )}
                </>
              )}
              {insPrice !== undefined &&
                insPrice !== null &&
                insPrice !== 0 &&
                !hideMainPrice && (
                  <BrandComponent
                    componentName="StepPrice"
                    parameter={{
                      price: strikePrice,
                      discount: strikePrice - insPrice,
                      insPrice: insPrice,
                      hideSign: true,
                      priceStyle: { marginLeft: 4 },
                      ignorePriceLabels: true,
                      ignoreInsurance: isNonPrescriptionAvailable(),
                      discountLoading: someIsLoading(),
                    }}
                  />
                )}
            </div>
          )}
        </div>
        {selection.map((sel, index) => {
          if (sel == null) {
            return (
              <span
                key={"StepInReview_" + index}
                className={
                  "StepInReview__mainContainer__contentContainer__selection" +
                  (showSeeDetails ? " seeDetails" : "")
                }
              >
                <TranslateText label={"noTreatment"} />{" "}
              </span>
            );
          }
          if (typeof sel === "object") {
            return (
              <span
                key={sel + "_StepInReview_" + index}
                className={
                  "StepInReview__mainContainer__contentContainer__selection"
                }
              >
                {Object.keys(sel).map((x, index) => {
                  return (
                    <div className="StepInReview__mainContainer__contentContainer__selectionContainer">
                      {" "}
                      <TranslateText label={sel[x]} /> <br></br>{" "}
                      {((insuranceEnabled && insPrice !== undefined) ||
                        !insuranceEnabled) && (
                        <BrandComponent
                          componentName="StepPrice"
                          parameter={{
                            price: index === 0 && strikePrice ? strikePrice : 0,
                            discount:
                              index === 0 ? strikePrice - offerPrice : 0,
                            insPrice: index === 0 ? insPrice : 0,
                            hideSign: false,
                            priceStyle: {
                              marginLeft: 4,
                            },
                            ignorePriceLabels: true,
                            ignoreInsurance: isNonPrescriptionAvailable(),
                            discountLoading: someIsLoading(),
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </span>
            );
          } else if (typeof sel === "string") {
            return (
              <span
                key={sel + "_StepInReview_" + index}
                className={
                  "StepInReview__mainContainer__contentContainer__selection" +
                  (showSeeDetails ? " seeDetails" : "")
                }
              >
                {sel && <TranslateText label={sel} />}
                {showSeeDetails && (
                  <span
                    className="StepInReview__mainContainer__contentContainer__selection__seeDetails"
                    onClick={() =>
                      reduxDispatch(
                        modalsActions.setShowPrescriptionReviewSeeDetails(true)
                      )
                    }
                  >
                    <TranslateText label={"steps.review.seeDetails"} />
                  </span>
                )}
                {!sel && <TranslateText label={"noTreatment"} />}
              </span>
            );
          }
        })}
      </div>
    </div>
  );
}
