import { groupBy, orderBy } from "lodash";
import { getFrameOnlyKey, hasValidPrescription } from "../../../ConfigLoader";

const getConfigByStepName = (
  stepName: string,
  lensesDataContent,
  configPackages
) => {
  const mapStepNameToContentKey = (stepName: string) => {
    switch (stepName) {
      case "Type":
        return "type";
      case "Brand":
      case "LastStepBrand":
        return "brand";
      case "TransitionColor":
        return "color";
      case "LensColor":
        return "color";
      case "GVPTreatment":
        return "treatment";
      case "DesignType":
        return "designType";
      case "Thickness":
        return "thickness";
      case "AddOns":
        return "antiReflective";
      case "blueLight":
        return "blueLight";
      case "transition":
        return "transition";
    }
    return null;
  };
  let mappedKey = mapStepNameToContentKey(stepName);
  let entries = null;

  if (mappedKey) {
    entries = lensesDataContent ? lensesDataContent[mappedKey] : null;
  }
  if (
    !mappedKey ||
    !entries ||
    Object.keys(lensesDataContent[mappedKey]).length == 0
  ) {
    if (stepName === "Treatment") {
      let blueLightContent = lensesDataContent["blueLight"];
      blueLightContent = Object.keys(blueLightContent).map((key) => {
        let ret = {};
        let contentObj = { ...blueLightContent[key] };
        contentObj.key = "blueLight";
        ret[key] = contentObj;
        return ret;
      });

      let transitionContent = lensesDataContent["transition"];
      transitionContent = Object.keys(transitionContent).map((key) => {
        let ret = {};
        let contentObj = { ...transitionContent[key] };
        contentObj.key = "transition";
        ret[key] = contentObj;
        return ret;
      });
      blueLightContent.forEach((element) => {
        entries = { ...entries, ...element };
      });
      transitionContent.forEach((element) => {
        entries = { ...entries, ...element };
      });
    }
    if (stepName === "TreatmentsFamily") {
      let blueLightContent = lensesDataContent["blueLight"];
      blueLightContent = Object.keys(blueLightContent).map((key) => {
        let ret = {};
        let contentObj = { ...blueLightContent[key] };
        contentObj.key = "blueLight";
        ret[key] = contentObj;
        return ret;
      });

      let transitionContent = lensesDataContent["transition"];
      transitionContent = Object.keys(transitionContent).map((key) => {
        let ret = {};
        let contentObj = { ...transitionContent[key] };
        contentObj.key = "transition";
        ret[key] = contentObj;
        return ret;
      });

      if (
        lensesDataContent["treatmentFamily"] &&
        Object.keys(lensesDataContent["treatmentFamily"]).length > 0
      ) {
        let keys = Object.keys(lensesDataContent["treatmentFamily"]);
        keys.map((k) => {
          let finded = configPackages.find(
            (pkg) => pkg.lensPackage.treatmentFamily == k
          );
          if (finded) {
            let obj = {};
            obj[k] = {
              ...lensesDataContent["treatmentFamily"][k],
              key: "treatmentFamily",
              id: k,
              treatmentKey:
                finded.lensPackage.transition !== "" &&
                  finded.lensPackage.transition !== null
                  ? "transition"
                  : "blueLight",
            };
            transitionContent = [obj];
          }
        });
      }

      entries = {
        Clear: {
          key: null,
          sequence: 0,
        },
      };
      blueLightContent.forEach((element) => {
        entries = { ...entries, ...element };
      });
      transitionContent.forEach((element) => {
        entries = { ...entries, ...element };
      });

      entries = {
        ...entries,
        Sun: {
          key: "color",
          sequence: 100,
        },
      };
    }
  }

  if (entries) {
    let ret = null;
    if (stepName === "blueLight" || stepName == "transition") {
      ret = Object.entries<Record<string, any>>(entries).map(([key, val]) => ({
        ...val,
        id: key,
        key: stepName,
      }));
    } else {
      ret = Object.entries<Record<string, any>>(entries).map(([key, val]) => ({
        ...val,
        id: key,
      }));
    }

    return orderBy(ret, "sequence");
  }
  return null;
};

export const computeRIAFlowTree = (
  configPackages,
  configContent,
  stepChains,
  currentPrescription,
  lensesDataContent,
  flowConfig
) => {
  let packagesCopy = configPackages.map((pkg) => pkg);

  const sortedConfigPackages = packagesCopy
    .filter((pkg) => pkg.lensPackage.insurable)
    .sort((pkg1, pkg2) => {
      if (
        parseFloat(pkg1.lensPackage.listPrice) >
        parseFloat(pkg2.lensPackage.listPrice)
      ) {
        return 1;
      }
      if (
        parseFloat(pkg1.lensPackage.listPrice) <
        parseFloat(pkg2.lensPackage.listPrice)
      ) {
        return -1;
      }
      return 0;
    });

  const frameOnlyKey = getFrameOnlyKey(
    sortedConfigPackages,
    configContent.data
  );

  const flowTree: any[] = [];
  let toVisit: any[] = [];

  let treeLevel = 0;
  toVisit.splice(0, 0, {
    level: 0,
    id: "Type",
    data: stepChains.Type,
    packages: sortedConfigPackages,
  });

  while (toVisit.length > 0) {
    let obj: {
      id: string;
      level: number;
      prev: string;
      prevChain: { step: string; key: string; option: string; level: number }[];
      key: string;
      next: { step: string; include: string[]; exclude: string[] }[];
      data: {
        key: string;
        next: {
          key: string;
          step: string;
          include: string[];
          exclude: string[];
        }[];
      };
      packages?: any;
    } = toVisit.pop();

    if (obj.id === "GVPTreatment") {
      let contentOptions = getConfigByStepName(
        obj.id,
        lensesDataContent,
        configPackages
      );
      let groupedByContent = groupBy(
        obj.packages.filter((pkg) =>
          contentOptions.some((opt) => opt.id === pkg.lensPackage.treatment)
        ),
        "lensPackage.treatment"
      );
      let toPushInTree = [];
      contentOptions.forEach((opt) => {
        let packages = groupedByContent[opt.id];
        if (packages && packages.length) {
          toPushInTree.push({
            option: opt.id,
            key: "treatment",
            packages: packages,
            step: obj.id,
            prevChain: obj.prevChain,
          });

          if (obj.data.next) {
            let testPkg = packages[0];
            let stepToPush = null;
            obj.data.next.forEach((nextStep) => {
              let excludeCheck =
                nextStep.exclude && nextStep.exclude.length
                  ? nextStep.exclude.every((excK) => !testPkg.lensPackage[excK])
                  : true;
              let includeCheck =
                nextStep.include && nextStep.include.length
                  ? nextStep.include.every(
                    (inclK) =>
                      testPkg.lensPackage[inclK] !== null &&
                      testPkg.lensPackage[inclK] !== undefined &&
                      testPkg.lensPackage[inclK].toString().trim() !== ""
                  )
                  : true;
              if (excludeCheck && includeCheck) {
                stepToPush = nextStep;
              }
            });
            if (stepToPush) {
              toVisit.splice(0, 0, {
                level: obj.level + 1,
                id: stepToPush.step,
                data: stepChains[stepToPush.step],
                packages: packages,
                prevChain: obj.prevChain
                  ? [
                    ...obj.prevChain,
                    {
                      step: obj.id,
                      key: "treatment",
                      option: opt.id,
                      level: obj.level,
                    },
                  ]
                  : [
                    {
                      step: obj.id,
                      key: "treatment",
                      option: opt.id,
                      level: obj.level,
                    },
                  ],
              });
            }
          }
        }
      });

      if (toPushInTree.length) {
        if (!flowTree.find((level) => level.level === treeLevel)) {
          flowTree.push({
            level: treeLevel,
            options: toPushInTree,
          });
        } else {
          flowTree[treeLevel].options.push(...toPushInTree);
        }
      }
      if (!toVisit.find((node) => node.level === treeLevel)) {
        //all the nodes for this level have been visited, so we increase the treeLevel
        treeLevel += 1;
      }
    } else {
      let treatmentFamilyKeys = null;
      if (
        obj.id == "Treatment" &&
        obj.prevChain.find((s) => s.step == "TreatmentsFamily")
      ) {
        let keys = obj.data.next.map((n) => n.key);
        treatmentFamilyKeys = keys;
      } else {
        treatmentFamilyKeys = [obj.id];
      }
      let toPushInTree = [];
      treatmentFamilyKeys.forEach((element) => {
        let contentOptions = getConfigByStepName(
          element,
          lensesDataContent,
          configPackages
        );
        const key =
          obj?.data?.key !== "treatmentFamily" ||
            !configContent.lensesData.content.treatmentFamily ||
            !Object.keys(configContent.lensesData.content.treatmentFamily)
            ? obj?.data?.key
            : null;
        let groupedByContent = null;

        if (key) {
          groupedByContent = groupBy(
            obj.packages.filter((pkg) =>
              contentOptions?.some((opt) => opt.id === pkg.lensPackage[key])
            ),
            "lensPackage." + key
          );
        } else {
          groupedByContent = {};
          contentOptions?.forEach((opt) => {
            let filteringData = obj.data.next.find((el) => {
              if (opt["treatmentKey"]) {
                return el.key === opt["treatmentKey"];
              } else {
                return el.key === opt["key"];
              }
            });
            let filteredPackages = obj.packages.filter((pkg) => {
              let pkgHasKey = null;
              if (opt["treatmentKey"]) {
                pkgHasKey = opt.key
                  ? pkg.lensPackage[opt.key] === opt.id
                  : true;
              } else {
                pkgHasKey = filteringData.key
                  ? pkg.lensPackage[filteringData.key] === opt.id
                  : true;
              }

              let hasInclude = filteringData.include
                ? filteringData.include.every(
                  (prop) =>
                    pkg.lensPackage[prop] && pkg.lensPackage[prop] !== ""
                )
                : true;
              let hasExclude = filteringData.exclude
                ? filteringData.exclude.every(
                  (prop) =>
                    !pkg.lensPackage[prop] ||
                    pkg.lensPackage[prop].toString().trim() === ""
                )
                : true;
              return pkgHasKey && hasInclude && hasExclude;
            });
            groupedByContent[opt.id] = filteredPackages;
          });
        }

        contentOptions?.forEach((opt) => {
          let packages = groupedByContent[opt.id];
          if (packages && packages.length) {
            if (opt.id === frameOnlyKey) {
              //insert frame only as first option
              toPushInTree.splice(0, 0, {
                option: opt.id,
                key: key ? key : opt["key"],
                packages: packages,
                step: obj.id,
                prevChain: obj.prevChain,
              });
            } else {
              toPushInTree.push({
                option: opt.id,
                key: key ? key : opt["key"],
                packages: packages,
                step: obj.id,
                prevChain: obj.prevChain,
              });
            }
            if (obj.data.next) {
              if (key) {
                obj.data.next.forEach((nextStep) => {
                  const singleStepConf = stepChains[nextStep.step];
                  if (
                    !singleStepConf?.include ||
                    (singleStepConf?.include &&
                      ((Boolean(singleStepConf?.include.equals) === false &&
                        !flowConfig[singleStepConf?.include.value]) ||
                        (Boolean(singleStepConf?.include.equals) === true &&
                          flowConfig[singleStepConf?.include.value])))
                  )
                    toVisit.splice(0, 0, {
                      level: obj.level + 1,
                      id: nextStep.step,
                      data: stepChains[nextStep.step],
                      packages:
                        currentPrescription && obj.id === "Type"
                          ? packages.filter((pkg) =>
                            hasValidPrescription(pkg, currentPrescription)
                          )
                          : packages,
                      prevChain: obj.prevChain
                        ? [
                          ...obj.prevChain,
                          {
                            step: obj.id,
                            key: key,
                            option: opt.id,
                            level: obj.level,
                          },
                        ]
                        : [
                          {
                            step: obj.id,
                            key: key,
                            option: opt.id,
                            level: obj.level,
                          },
                        ],
                    });
                });
              } else {
                //it's treatmentsFamily case
                let stepToPush = obj.data.next.find((nextStep) => {
                  return opt.treatmentKey
                    ? nextStep.key === opt.treatmentKey
                    : nextStep.key === opt["key"];
                });
                if (stepToPush) {
                  toVisit.splice(0, 0, {
                    level: obj.level + 1,
                    id: stepToPush.step,
                    data: stepChains[stepToPush.step],
                    packages:
                      currentPrescription && obj.id === "Type"
                        ? packages.filter((pkg) =>
                          hasValidPrescription(pkg, currentPrescription)
                        )
                        : packages,
                    prevChain: obj.prevChain
                      ? [
                        ...obj.prevChain,
                        {
                          step: obj.id,
                          key: opt["key"],
                          option: opt.id,
                          level: obj.level,
                        },
                      ]
                      : [
                        {
                          step: obj.id,
                          key: opt["key"],
                          option: opt.id,
                          level: obj.level,
                        },
                      ],
                  });
                }
              }
            }
          }
        });


      });
      if (toPushInTree.length) {
        if (!flowTree.find((level) => level.level === treeLevel)) {
          flowTree.push({
            level: treeLevel,
            options: toPushInTree,
          });
        } else {
          flowTree[treeLevel].options.push(...toPushInTree);
        }
      }
      if (!toVisit.find((node) => node.level === treeLevel)) {
        //all the nodes for this level have been visited, so we increase the treeLevel
        treeLevel += 1;
      }
    }
  }
  return { flowTree, frameOnlyKey };
};
