import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workflowActions } from "../../redux/slices/workflow";
import { TranslateText } from "../common/TranslateText";
import "./default.module.scss";
import { useExternalAutoselect } from "@hooks";

interface SkipContainerProps {
  stepName: string;
  children: React.ReactNode[];
  ignoreSkip?: boolean;
  onNext: (selected: any, skipped?: boolean) => void;
  continueButtonClass?: string;
  continueButtonText?: string;
  parameterPreselection: string[];
  onPreselect: (selected: any, skipped: boolean) => void;
  forceRender?: boolean;
}

export function SkipContainer({
  children,
  onNext,
  stepName,
  continueButtonClass,
  continueButtonText,
  ignoreSkip,
  parameterPreselection,
  onPreselect,
  forceRender,
}: SkipContainerProps) {
  const reduxDispatch = useDispatch();

  const handleSelectClick = (element, selectionMode) => {
    setSelected(element);
    if (selectionMode !== "CONTINUE") {
      goNext(element, false);
    }
  };

  const [renderized, setRenderized] = useState(null);
  const [selected, setSelected] = useState(null);
  const [showContinue, setShowContinue] = useState(false);
  const [skipped, setSkipped] = useState(false);

  const isSelected = (element) => element.id === selected?.id;
  const skipConfiguration = useSelector(
    (state: any) => state.config?.skipConfiguration
  );

  const preselectedValues = useExternalAutoselect(parameterPreselection);

  const goNext = (element, skipped) => {
    if (typeof onNext === "function") {
      onNext(element, skipped);
      setSkipped(true);
    }
  };

  const goPreselected = (element, skipped) => {
    if (typeof onPreselect === "function") {
      onPreselect(element, skipped);
      setSkipped(true);
    }
  };

  const getMode = () => {
    return !skipConfiguration || !skipConfiguration[stepName]
      ? "SKIP"
      : skipConfiguration[stepName];
  };

  useEffect(() => {
    if (
      children &&
      stepName &&
      !skipped &&
      ((parameterPreselection && preselectedValues !== undefined) ||
        !parameterPreselection)
    ) {
      let isPreselected = false;

      if (preselectedValues && typeof onPreselect === "function") {
        isPreselected = true;
      }

      const countChildren = React.Children.count(children);
      if (countChildren === 0) {
        goNext(null, true);
      } else {
        if (!ignoreSkip) {
          if (countChildren === 1) {
            const remapped = React.Children.map(children, (ct: any) => {
              if (ct.props.parameter) {
                ct.props.parameter.onClick = () =>
                  handleSelectClick(ct.props.parameter, getMode());
              }

              return React.cloneElement(ct, {
                key: ct.key,
              });
            });
            const element = remapped[0].props.parameter || remapped[0].props;
            if (getMode() === "SKIP") {
              if (isPreselected) {
                goPreselected(preselectedValues, true);
              } else {
                goNext(element, true);
                if (forceRender) {
                  setRenderized(children);
                  reduxDispatch(workflowActions.setLoadingStep(false));
                }
              }
            } else if (getMode() === "CONTINUE") {
              if (isPreselected) {
                goPreselected(preselectedValues, false);
              } else {
                setSelected(element);
                setShowContinue(true);
                setRenderized(remapped);
                reduxDispatch(workflowActions.setLoadingStep(false));
              }
            } else {
              if (isPreselected) {
                goPreselected(preselectedValues, false);
              } else {
                setRenderized(children);
                reduxDispatch(workflowActions.setLoadingStep(false));
              }
            }
          } else {
            if (isPreselected) {
              goPreselected(preselectedValues, false);
            } else {
              setRenderized(children);
              reduxDispatch(workflowActions.setLoadingStep(false));
            }
          }
        } else {
          if (isPreselected) {
            goPreselected(preselectedValues, false);
          } else {
            setRenderized(children);
            reduxDispatch(workflowActions.setLoadingStep(false));
          }
        }
      }
    }
  }, [children, skipConfiguration, onNext, stepName, preselectedValues]);

  if (ignoreSkip) return <>{renderized}</>;

  return (
    <span className="SkipContainer__container">
      {renderized &&
        renderized.map((r) => (
          <span
            key={r.key}
            className={
              "SkipContainer Step-" +
              stepName +
              (isSelected(r.props.parameter) ? " selected" : "")
            }
          >
            {r}
          </span>
        ))}
      {showContinue && (
        <div className="SkipContainer__btn_container">
          <button
            className={
              continueButtonClass ? continueButtonClass : "SkipContainer__btn"
            }
            disabled={!selected}
            type="button"
            onClick={() => goNext(selected, false)}
          >
            {continueButtonText || <TranslateText label="continue" />}
          </button>
        </div>
      )}
    </span>
  );
}
