import React, { FC, ReactElement, useEffect } from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";
import GenericButton from "../../common/GenericButton";
import { useCurrentBrand, useEnablePrismComment } from "@hooks";
import { useEnablePrism } from "@hooks";

type MoreOptionsProps = {
  isMoreOptionsFormOpen;
  toggleMoreOptionsOpen;
  showPrismValuesForm;
  showPrismMissingValuesError;
  comment: string | null;
  setComment: Function;
  onSubmit: Function;
  checkbox: ReactElement;
  errorMessage: ReactElement;
  FormTable: ReactElement;
};
export const MoreOptions = (props: MoreOptionsProps) => {
  const enablePrismComment = useEnablePrismComment();
  const brand = useCurrentBrand();
  const enablePrism = useEnablePrism();
  const moreOptions = useTranslate(
    "steps.advancedPrescription.manual.moreOptions"
  );
  const addPrismValues = useTranslate(
    "steps.advancedPrescription.manual.addPrismValues"
  );
  const verticalName = useTranslate(
    "steps.advancedPrescription.manual.vertical"
  );
  const baseDirectionName = useTranslate(
    "steps.advancedPrescription.manual.baseDirection"
  );
  const horizontalName = useTranslate(
    "steps.advancedPrescription.manual.horizontal"
  );
  const comments = useTranslate(
    "steps.advancedPrescription.manual.commentsTitle"
  );

  return (
    <>
      {enablePrism && !enablePrismComment ? (
        <>
          {!props.isMoreOptionsFormOpen && (
            <div
              className="MoreOptions__checkboxContainer_noMoreOptions"
              style={
                !enablePrismComment && {
                  marginBottom: "0px",
                }
              }
            >
              {enablePrism && (
                <div
                  style={{
                    display: "flex",
                    width: "145px",
                  }}
                >
                  {props.checkbox}
                  <div className="MoreOptions__checkBoxText">
                    {addPrismValues}
                  </div>
                </div>
              )}
              <div className="MoreOptions__formWrapper">
                {props.showPrismValuesForm && props.FormTable}
                {props.showPrismValuesForm &&
                  props.showPrismMissingValuesError &&
                  props.errorMessage}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={"MoreOptions__accordionBtnContainer"}>
          <GenericButton
            className={
              props.isMoreOptionsFormOpen
                ? "MoreOptions__accordionBtnContainer__accordionBtnOpen"
                : "MoreOptions__accordionBtnContainer__accordionBtnClosed"
            }
            title="More options"
            id="moreOptionsAccordionButton"
            type="button"
            noArrow={true}
            handleClick={props.toggleMoreOptionsOpen}
          >
            {moreOptions}
          </GenericButton>
        </div>
      )}

      {props.isMoreOptionsFormOpen && (
        <>
          <div
            className={
              brand === "default"
                ? "MoreOptions__checkboxContainer_noMoreOptions_default"
                : "MoreOptions__checkboxContainer_noMoreOptions"
            }
            style={!enablePrism ? { display: "none" } : {}}
          >
            {enablePrism && (
              <div
                style={
                  brand !== "rayban"
                    ? { display: "inline", marginTop: "24px" }
                    : { display: "flex", alignItems: "center" }
                }
              >
                {props.checkbox}
                <div
                  className="MoreOptions__checkBoxText"
                  style={{ display: "inline" }}
                >
                  {addPrismValues}
                </div>
              </div>
            )}
          </div>
          {props.showPrismValuesForm && (
            <div className="MoreOptions__formWrapper">{props.FormTable}</div>
          )}
          {props.showPrismValuesForm &&
            props.showPrismMissingValuesError &&
            props.errorMessage}

          {enablePrismComment && (
            <div className="MoreOptions__commentContainer">
              <div
                className={"MoreOptions__commentContainer__comments_title"}
                style={
                  enablePrism && props.showPrismValuesForm
                    ? { marginTop: "20px" }
                    : {}
                }
              >
                {comments}
              </div>
              <textarea
                id="comment"
                rows={6}
                name="comment"
                title="comment"
                defaultValue={props.comment}
                onBlur={(e) => {
                  props.setComment(e.target.value);
                  if (
                    e.relatedTarget &&
                    e.relatedTarget["id"] === "submitButtonId"
                  ) {
                    props.onSubmit(e);
                  }
                }}
              ></textarea>
            </div>
          )}
        </>
      )}
    </>
  );
};
