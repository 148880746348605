import { RIAEngine } from "./core/RIAEngine";
import { RIAAPICall, RIAAPICallType } from "./core/types/RIAAPICall";
import { RIACacheEntry } from "./core/types/RIACacheEntry";
import { insuranceActions } from "../../../redux/slices/insurance";
import { modalsActions } from "../../../redux/slices/modals";
import { configActions } from "../../../redux/slices/config";
import { workflow } from "../../models/Workflow";
import { frameToInsuranceFrameAdapter } from "../../adapters/FrameToInsuranceFrameAdapter";

export enum DiscountMethod {
  STANDARD = "STANDARD",
  QUICK = "QUICK",
  STANDARD_REVIEW = "STANDARD_REVIEW",
}

export class RIAManager extends RIAEngine {
  private static _instance: RIAManager;

  config: any;
  dispatch: Function;
  blockCompletedCallback: Function;

  private constructor(
    config: any,
    discountMethod: DiscountMethod,
    dispatchFunction: Function,
    blockCompletedCallback: Function
  ) {
    let frame = frameToInsuranceFrameAdapter.adapt(config.data.frame);
    let discountFunction = null;

    switch (discountMethod) {
      case DiscountMethod.QUICK:
        discountFunction = config.insuranceModule.getQuickInsuranceDiscounts;
        break;
      case DiscountMethod.STANDARD_REVIEW:
        if (workflow.isLastStep()) {
          discountFunction = config.insuranceModule.getInsuranceDiscounts;
        } else {
          discountFunction = config.insuranceModule.getQuickInsuranceDiscounts;
        }
        break;
      default:
        discountFunction = config.insuranceModule.getInsuranceDiscounts;
        break;
    }

    super(discountFunction, frame);

    this.config = config;
    this.dispatch = dispatchFunction;
    this.blockCompletedCallback = blockCompletedCallback;
  }

  public static getInstance(
    config: any,
    discountMethod: DiscountMethod,
    dispatchFunction: Function,
    blockCompletedCallback: Function
  ): RIAManager {
    if (!this._instance) {
      this._instance = new this(
        config,
        discountMethod,
        dispatchFunction,
        blockCompletedCallback
      );
      return this._instance;
    } else {
      this._instance.blockCompletedCallback = blockCompletedCallback;
      switch (discountMethod) {
        case DiscountMethod.QUICK:
          this._instance.setInsuranceFunction(
            config.insuranceModule.getQuickInsuranceDiscounts
          );
          break;
        case DiscountMethod.STANDARD_REVIEW:
          if (workflow.isLastStep()) {
            this._instance.setInsuranceFunction(
              config.insuranceModule.getInsuranceDiscounts
            );
          } else {
            this._instance.setInsuranceFunction(
              config.insuranceModule.getQuickInsuranceDiscounts
            );
          }
          break;
        default:
          this._instance.setInsuranceFunction(
            config.insuranceModule.getInsuranceDiscounts
          );
          break;
      }
      return this._instance;
    }
  }

  test() {
    let lensPackages = this.config.lensesData.packages;

    let requests: RIAAPICall[] = [
      /* {
                type: RIAAPICallType.MULTIPLE_LP,
                packages: [lensPackages[0], lensPackages[1], lensPackages[2]]
            },
            {
                type: RIAAPICallType.SINGLE_LP,
                packages: [lensPackages[3]]
            },
            {
                type: RIAAPICallType.MULTIPLE_LP,
                packages: [lensPackages[4], lensPackages[5]]
            }, */
      {
        type: RIAAPICallType.MULTIPLE_LP,
        packages: [lensPackages[0], lensPackages[1], lensPackages[2]],
      },
    ];

    setTimeout(() => {
      this.callAPI(requests);
    }, 3000);
  }

  onCallBlockCompleted(catEntryIDs: string[]) {
    console.log("RIAManager - Completed");
    this.blockCompletedCallback(catEntryIDs);
  }

  onCallBlockError(error: any) {
    console.error(error);
    try {
      //@ts-ignore
      window.tealium_data2track.push({
        id: "Error",
        Error_Code: "RX Configurator: Loading error",
        Error_Details: error,
        Error_Source: "Client",
      });
    } catch (error) {
      console.error(
        "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
      );
    }
    if (!this.config.insuranceModule.isLogged()) {
      this.dispatch(insuranceActions.setInsuranceEnabled(false));
    } else {
      this.config.insuranceModule.removeInsuranceBenefits() ?? null;
      this.dispatch(insuranceActions.setInsuranceEnabled(false));
      this.dispatch(modalsActions.setShowInsuranceErrorPopup(true));
    }
  }

  writeToCache(entries: RIACacheEntry[]) {
    console.log("RIAManager - Write to cache for entries: " + entries);

    let result = entries.map((entry) => entry.value).filter((pkg) => pkg);
    if (result.length) {
      if (this.config.insuranceModule.isEnabled()) {
        this.dispatch(
          configActions.setInsurancePackage({
            packages: result,
            frame: this.config.data.frame,
          })
        );
        this.dispatch(insuranceActions.setInsuranceEnabled(true));
      } else {
        throw new Error("ERROR while retrieving insurance discount");
      }
    }
  }
}
