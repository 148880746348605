import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "../translations/TranslationHooks";
import { useProtectionPlan } from "./useProtectionPlan";

export function useWarrantyOptions() {
  const enableEPPStep = useProtectionPlan();
  const translate = useTranslation();
  const warrantyOptions = useSelector(
    (state: any) => state?.config?.lensesData?.warrantyOptions
  );
  const noProtection = {
    id: "asIs",
    title: translate("steps.protectionplan.asIs"),
    price: null,
    discount: 0,
    insPrice: 0,
  };
  const currentLensType = useSelector(
    (state: any) => state?.workflow?.lensType
  );
  const filteredWarrantyOptions =
    warrantyOptions?.length > 0 &&
    warrantyOptions.filter((wo: any) =>
      wo?.type?.toLowerCase().includes(currentLensType?.toLowerCase())
    );
  const firstWarrantyOption = warrantyOptions?.[0];

  let fullWarrantyOptions = enableEPPStep ? [noProtection] : [];

  if (filteredWarrantyOptions.length > 0) {
    fullWarrantyOptions = [...fullWarrantyOptions, ...filteredWarrantyOptions];
  } else {
    if (firstWarrantyOption) {
      fullWarrantyOptions.push(firstWarrantyOption);
    }
  }
  return fullWarrantyOptions;
}
