import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrandComponent, TranslateText } from "../../../../docFiles";
import { getCorrectAnalyticsStepContainerName } from "../../ConfigLoader";
import {
  useAllCurrentPackages,
  useConfigContent,
  useCollapsedTransitionColorStep,
  useTransitionSelectedColor,
  usePriceStepItem,
} from "@hooks";
import { useTranslation } from "../../translations/TranslationHooks";
import Image from "../../../components/common/Image";
import "./default.module.scss";
import GenericButton from "../../../components/common/GenericButton";
import { workflow } from "../../models/Workflow";
import ReferencedSection from "../../../components/common/ReferencedSection";

export function TransitionColor() {
  const configColor = useConfigContent("Color");
  const translation = useTranslation();
  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );
  const currentPackages = useAllCurrentPackages();

  const [colors, setColors] = useState<any>(null);
  const [selectedColor, setSelectedColor] = useState(null);

  const changeColor = useTransitionSelectedColor();
  const { isCollapsedTransitionColorStep } = useCollapsedTransitionColorStep();
  const isDesignStepEnabled = useSelector(
    (state: any) => state.config?.layoutSettings?.enableDesignTypeStep
  );
  const c = usePriceStepItem();

  useEffect(() => {
    if (changeColor)
      return () => {
        changeColor(null);
      };
  }, [changeColor]);

  useEffect(() => {
    if (configColor && currentPackages) {
      const colors = configColor.filter((c) =>
        currentPackages.validRXPackages.some(
          (cp) => cp.lensPackage.color === c.id
        )
      );
      const invalidRXPackagesFilteredOnColors = configColor
        .filter((c) =>
          currentPackages.invalidRXPackages.some(
            (cp) => cp.lensPackage.color === c.id
          )
        )
        .map((color) => {
          return {
            ...color,
            disabled: true,
          };
        });
      const validRXPackagesFilteredOnColors = configColor
        .filter((c) =>
          currentPackages.validRXPackages.some(
            (cp) => cp.lensPackage.color === c.id
          )
        )
        .map((color) => {
          return {
            ...color,
            disabled: false,
          };
        });
      const greyOutColors = invalidRXPackagesFilteredOnColors.filter(
        (inv: any) =>
          !validRXPackagesFilteredOnColors.some((val: any) => val.id === inv.id)
      );
      if (greyOutColors.length > 0) {
        let filtered = colors.filter(
          (color: any) =>
            !greyOutColors.some((gcolor: any) => gcolor.id === color.id)
        );
        setColors([...filtered, ...greyOutColors]);
      } else {
        setColors([...colors, ...greyOutColors]);
      }
      if (!selectedColor) {
        if (changeColor) {
          setSelectedColor(colors[0].id);
          changeColor(colors[0].id);
        }
      }
    }
  }, [configColor, selectedColor, changeColor, currentPackages]);

  const selectColor = (colorId, callback?) => {
    setSelectedColor(colorId);
    changeColor(colorId);
    if (callback) {
      callback();
    }
  };

  const handleNextStep = (skipped?: boolean, preSelectedColor?: any) => {
    let selection = preSelectedColor;
    if (selection && typeof selection !== "string") {
      selection = preSelectedColor.id;
    }

    const colorToFind = selection ? selection : selectedColor;
    const colorPrice = c(
      { color: colorToFind },
      currentPackages.validRXPackages
    );

    let truthValue = false;

    Object.keys(colorPrice).forEach((e) => {
      truthValue =
        truthValue || (colorPrice[e] !== 0 && colorPrice[e] !== null);
    });

    let spread = truthValue ? colorPrice : {};

    /* se design type as stand alone vai a designType altrimenti a thickness */
    if (isDesignStepEnabled) {
      workflow.goToStep(
        "DesignType",
        [
          {
            key: "color",
            value: selection ? selection : selectedColor,
            ...spread,
          },
          {
            key: "transitionColor",
            value: selection ? selection : selectedColor,
            ...spread,
          },
        ],
        "boolean" === typeof skipped ? skipped : null
      );
    } else {
      workflow.goToStep(
        "Thickness",
        [
          {
            key: "color",
            value: selection ? selection : selectedColor,
            ...spread,
          },
          {
            key: "transitionColor",
            value: selection ? selection : selectedColor,
            ...spread,
          },
        ],
        "boolean" === typeof skipped ? skipped : null
      );
    }
    // workflow.goNextStep(
    //   [
    //     {
    //       key: "color",
    //       value: selection ? selection : selectedColor,
    //       ...spread,
    //     },
    //     {
    //       key: "transitionColor",
    //       value: selection ? selection : selectedColor,
    //       ...spread,
    //     },
    //   ],
    //   "boolean" === typeof skipped ? skipped : null
    // );
  };

  const getDataElementIdForCard = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-Choose";
  };

  const CardComponent = ({ color, parameter }) => {
    const imageFileName = color.imageFileName2
      ? color.imageFileName2
      : color.imageFileName;
    return (
      <a
        tabIndex={0}
        data-element-id={getDataElementIdForCard()}
        data-description={color.title}
        key={"TransitionColor_card_" + color.id}
        className={
          "TransitionColor__card" +
          (selectedColor === color.id ? " selected" : "") +
          (color.disabled ? " disabled" : "")
        }
        onClick={color.disabled ? null : () => selectColor(color.id)}
      >
        <Image
          imageClass="TransitionColor__card__img"
          url={configProjectImage}
          fileName={imageFileName}
        />
        <div className="TransitionColor__card__text">
          <p>{color.title}</p>
        </div>
      </a>
    );
  };

  const getColorsList = () => {
    let list = null;
    if (colors) {
      list = [];
      colors.forEach((c) =>
        list.push(<CardComponent color={c} parameter={c} />)
      );
    }

    return list;
  };

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: translation("steps.color.title"),
        showGrayOutBanner: colors && colors.find((c) => c.disabled),
      }}
    >
      <div className={"TransitionColor__container"}>
        {colors && (
          <BrandComponent
            componentName="SkipContainer"
            parameter={{
              stepName: "TransitionColor",
              onNext: (selected: any, skipped?: boolean) => {
                const photocromicParam = workflow.currentStep.params.find(
                  (f) => f.key === "photocromic"
                );
                if (photocromicParam && photocromicParam.value && skipped) {
                  handleNextStep(skipped);
                } else {
                  handleNextStep(skipped, selected);
                }
              },
              parameterPreselection: ["color"],
              onPreselect: (selected, skipped: boolean) => {
                selectColor(selected.color, () =>
                  handleNextStep(skipped, selected.color)
                );
              },
            }}
          >
            {getColorsList()}
          </BrandComponent>
        )}
      </div>

      <div className={"TransitionColor__btn_container"}>
        <GenericButton
          handleClick={handleNextStep}
          className={"TransitionColor__btn"}
          noArrow
        >
          <TranslateText label="steps.color.applyAndContinue" />
        </GenericButton>
      </div>
      <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
    </BrandComponent>
  );
}
