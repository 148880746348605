import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colorActions } from "../../redux/slices/color";
import { sunRule } from "../ConfigLoader";
import { workflow } from "../models/Workflow";
import { useCurrentPackages } from "./useCurrentPackages";
import { useCurrentBrand } from "./useCurrentBrand";

export function useProductImage() {
  const imageFrameLayer = useSelector(
    (state: any) => state.config?.imageFrameLayer
  );
  const brand = useCurrentBrand();
  const imageLens = useSelector((state: any) => state.config?.imageLens);
  const logoMap = useSelector((state: any) => state.config?.logoMap);
  const imageLogo = useSelector((state: any) => state.config?.imageLogo);
  const imageLensNoLogoLayer = useSelector(
    (state: any) => state.config?.imageLensNoLogoLayer
  );
  const imageLensLogoLayer = useSelector(
    (state: any) => state.config?.imageLensLogoLayer
  );
  const imageLogoLayer = useSelector(
    (state: any) => state.config?.imageLogoLayer
  );
  const imageLensPreview = useSelector(
    (state: any) => state.config?.imageLensPreview
  );
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);

  const baseUrlCdn = useSelector(
    (state: any) => state.config?.baseURLs?.assetsCDN
  );

  const selectedColor = useSelector((state: any) => state.color?.colorSelected);
  const brandFrame = useSelector(
    (state: any) => state.config?.data?.frame?.brand
  );
  const brandConfig = useSelector((state: any) => state.config.brand);
  //const frontImageUrl = useSelector((state: any) => state.config?.data?.frame?.frontImageUrl);
  let imgUrl = useSelector((state: any) => state.config?.data?.frame?.imageUrl);
  const checkImageUrlPolicy = (url: string) => {
    if (url) {
      if (brandConfig && brandConfig !== "clearly") {
        return url;
      } else {
        if (url.includes("impolicy=CLE")) {
          return url;
        } else {
          url = url + "?impolicy=CLE_resize&wid=1200";
          return url;
        }
      }
    }
  };
  let imageUrl = checkImageUrlPolicy(imgUrl);
  const modelFrame = useSelector(
    (state: any) => state.config?.data?.frame?.model
  );
  const colorFrame = useSelector(
    (state: any) => state.config?.data?.frame?.color
  );
  const category = useSelector(
    (state: any) => state.config?.data?.frame?.category
  );
  const lensBrand = useSelector((state: any) => state.color?.lensBrand);
  const [cancelToken, setCancelToken] = useState(null);
  const [urlImage, setUrlImage] = useState(selectedColor ? null : imageUrl);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const currentPackages = useCurrentPackages();

  const color = useSelector((state: any) => state.color);

  const getBrandByType = (isPolar: boolean) => {
    let brand = lensBrand ?? brandFrame;
    let ret = null;
    if (isPolar && brand === "Ray-Ban") {
      ret = logoMap[brand + "_P"];
    }
    if (!ret) ret = logoMap[brand];

    return ret;
  };
  const fallbackImage = () => {
    //(clen: string) => {

    dispatch(colorActions.setIsLoading(false));
    setUrlImage(imageUrl);
    dispatch(colorActions.setUrlImageGlobal(imageUrl));
    dispatch(colorActions.setShowFallbackImageMessage(true));
    /*
        let lensPreview = imageLensPreview
            .replace("##CLEN##", clen)
            .replace("##URL##", baseUrlCdn);
        dispatch(colorActions.setIsLoading(true));
        return axios
            .get(lensPreview, {
                //cancelToken: cancelToken.token,
            })
            .then(() => {
                setUrlImage(lensPreview);
                dispatch(colorActions.setShowFallbackImageMessage(true));
                dispatch(colorActions.setIsLoading(false));
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    //if (productImageOld) dispatch(setProductImage(productImageOld));
                    //dispatch(setImageErrorMessage(true));
                }
                dispatch(colorActions.setIsLoading(false));
                setUrlImage(imageUrl);
                dispatch(colorActions.setShowNotFoundImageMessage(true));
            });
        */
  };

  const isColoredTreatment = () => {
    return currentPackages?.every((pkg) => {
      return (
        pkg.lensPackage.coloredTreatment &&
        pkg.lensPackage.coloredTreatment !== ""
      );
    });
  };

  useEffect(() => {
    const coloredTreatment = isColoredTreatment();
    const sun = currentPackages?.find((cp: any) => sunRule(cp.lensPackage));
    if (
      colorFrame &&
      ((selectedColor &&
        (category === "SUNGLASSES" || (sun !== null && sun !== undefined)) &&
        currentStep &&
        (currentStep.progressive <=
          workflow?.findByStepKey("LensColor")?.progressive ||
          workflow.isLastStep())) ||
        coloredTreatment)
    ) {
      let url = imageFrameLayer
        .replace("##MODEL##", modelFrame)
        .replace("##COLOR##", colorFrame)
        .replace("##URL##", baseUrlCdn);

      if (
        workflow.currentStep?.progressive <
        workflow.findByStepKey("LensColor")?.progressive &&
        !coloredTreatment
      ) {
        setUrlImage(imageUrl);
        dispatch(colorActions.setUrlImageGlobal(imageUrl));
        dispatch(colorActions.selectColor(null));
      } else if (currentPackages && currentPackages.length > 0) {
        const packagesFromColor = coloredTreatment
          ? []
          : currentPackages.filter(
            (cp) => cp.lensPackage.color === selectedColor
          );
        const selectedColoredTreatment = coloredTreatment
          ? currentPackages[0].lensPackage?.coloredTreatment
          : null;
        if (packagesFromColor.length > 0 || selectedColoredTreatment) {
          const selectedColorPackage = packagesFromColor.length
            ? packagesFromColor[0]
            : selectedColoredTreatment;
          let urlLensesImage = imageLens
            .replace("##MODEL##", modelFrame)
            .replace(
              "##CLEN##",
              selectedColoredTreatment
                ? selectedColoredTreatment
                : selectedColorPackage.lensPackage.clen
            )
            .replace("##URL##", baseUrlCdn);

          if (cancelToken) cancelToken.cancel();
          const CancelToken = axios.CancelToken;
          const source = CancelToken.source();
          setCancelToken(source);

          dispatch(colorActions.setError(false));
          dispatch(colorActions.setIsLoading(true));
          dispatch(colorActions.setIsLoading(true));

          axios
            .get(urlLensesImage, {
              cancelToken: source.token,
            })
            .then(() => {
              if (brandFrame && (!lensBrand || brandFrame == lensBrand)) {
                const imageLogoMapped = getBrandByType(
                  selectedColoredTreatment
                    ? selectedColoredTreatment
                    : selectedColorPackage.polar
                );
                let urlLogoImage = imageLogo
                  .replace("##MODEL##", modelFrame)
                  .replace("##LOGO##", imageLogoMapped)
                  .replace("##URL##", baseUrlCdn);
                return axios
                  .get(urlLogoImage, {
                    cancelToken: source.token,
                  })
                  .then(() => {
                    let compositionLens = imageLensLogoLayer
                      .replace("##MODEL##", modelFrame)
                      .replace(
                        "##CLEN##",
                        selectedColoredTreatment
                          ? selectedColoredTreatment
                          : selectedColorPackage.lensPackage.clen
                      )
                      .replace("##URL##", baseUrlCdn);
                    url += compositionLens;
                    let compositionLogo = imageLogoLayer
                      .replace("##MODEL##", modelFrame)
                      .replace("##LOGO##", imageLogoMapped)
                      .replace("##URL##", baseUrlCdn);
                    url += compositionLogo;
                    return axios.get(url, {
                      cancelToken: source.token,
                    });
                  });
              } else {
                let compositionLens = imageLensNoLogoLayer
                  .replace("##MODEL##", modelFrame)
                  .replace(
                    "##CLEN##",
                    selectedColoredTreatment
                      ? selectedColoredTreatment
                      : selectedColorPackage.lensPackage.clen
                  )
                  .replace("##URL##", baseUrlCdn);
                url += compositionLens;
                return axios.get(url, {
                  cancelToken: source.token,
                });
              }
            })
            .then(() => {
              setUrlImage(url);
              dispatch(colorActions.setUrlImageGlobal(url));
              dispatch(colorActions.setError(true));
              dispatch(colorActions.setShowFallbackImageMessage(false));
              dispatch(colorActions.setShowNotFoundImageMessage(false));
              dispatch(colorActions.setIsLoading(false));
            })
            .catch((error) => {
              if (!selectedColoredTreatment && error.isAxiosError) {
                dispatch(colorActions.setIsLoading(true));
                return fallbackImage();
                // selectedColoredTreatment
                //     ? selectedColoredTreatment
                //     : selectedColorPackage.lensPackage.clen
              }
            });
        }
      }
    }
  }, [lensBrand, selectedColor, category, currentPackages, colorFrame]);

  return { urlImage, isLoading };
}
