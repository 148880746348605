import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "../../redux/slices/modals";
import "./default.module.scss";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import GenericButton from "../common/GenericButton";

interface MissingPrescriptionValuesProps {}

export function MissingPrescriptionValueModal({}: MissingPrescriptionValuesProps) {
  const showMissingPrescriptionsValue = useSelector(
    (state: any) => state.modals?.showMissingPrescriptionsValue
  );
  const reduxDispatch = useDispatch();
  const californianModalTitle = useTranslate(
    "steps.advancedPrescription.californian.modalMissingPrescription.title"
  );
  const californianModalSubTitle = useTranslate(
    "steps.advancedPrescription.californian.modalMissingPrescription.subtitle"
  );
  const californianModalButtomY = useTranslate(
    "steps.advancedPrescription.californian.modalMissingPrescription.bottonYes"
  );
  const californianModalButtomN = useTranslate(
    "steps.advancedPrescription.californian.modalMissingPrescription.bottonNo"
  );

  const handleSubmit = (value: boolean) => {
    reduxDispatch(modalsActions.setShowMissingPrescriptionsValue(false));

    reduxDispatch(modalsActions.setMissingPrescriptionsValueResult(value));
  };
  return (
    <>
      {showMissingPrescriptionsValue && (
        <>
          <div className="MissingPrescriptionValues__overlay" />
          <div className="MissingPrescriptionValues__container">
            <div className="MissingPrescriptionValues__container__title">
              {californianModalTitle}
            </div>
            <div className="MissingPrescriptionValues__container__text">
              {californianModalSubTitle}
            </div>
            <div className="MissingPrescriptionValues__container__buttons">
              <GenericButton
                id={"continueButton"}
                className={
                  "border-button " +
                  "MissingPrescriptionValues__container__continueButton"
                }
                noArrow={true}
                type="button"
                dataElementId="X_X_LensPanel_Type-ModalContinue"
                title={californianModalButtomY}
                tabIndex={0}
                handleClick={() => handleSubmit(true)}
              >
                {californianModalButtomY}
              </GenericButton>
              <GenericButton
                id={"cancelButton"}
                className={
                  "border-button " +
                  "MissingPrescriptionValues__container__cancelButton"
                }
                noArrow={true}
                type="button"
                dataElementId="X_X_LensPanel_Type-ModalCancel"
                title={californianModalButtomN}
                tabIndex={0}
                handleClick={() => handleSubmit(false)}
              >
                {californianModalButtomN}
              </GenericButton>
            </div>
          </div>
        </>
      )}
    </>
  );
}
