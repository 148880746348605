import React, { useEffect, useState } from "react";
import "./default.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { workflow } from "../../configurator/models/Workflow";
import { BrandComponent } from "../common/BrandComponent";
import { modalsActions } from "../../redux/slices/modals";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { workflowActions } from "../../../src/redux/slices/workflow";

interface MainHeaderProps {}

export function MainHeader({}: MainHeaderProps) {
  const currentStep = useSelector(
    (state: any) => state.workflow.currentStep?.progressive
  );
  const addInsuranceLabel = useTranslate("insuranceButtonLabel");
  const selectedPrescriptionMode = useSelector(
    (state: any) => state.workflow?.selectedPrescriptionMode
  );
  const dispatch = useDispatch();

  const reduxDispatch = useDispatch();

  const getDataElementIdForClose = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-BackToProd";
  };

  const getDataElementIdForBack = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-Back";
  };

  const getDataElementIdForInsurance = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-UseInsurance";
  };

  const getDataDescriptionForBack = () => {
    let landingStep = workflow.getLadindingStepFromBack();
    if (landingStep) {
      return getCorrectAnalyticsStepContainerName(landingStep.key);
    }
  };

  const handleBack = () => {
    if (selectedPrescriptionMode) {
      dispatch(workflowActions.setSelectedPrescriptionMode(false));
    } else {
      workflow.goPrevStep();
    }
  };

  const renderDesktop = () => {
    return (
      <div className={"MainHeader__container"}>
        <div className={"MainHeader__left"}>
          <div
            className={
              "MainHeader__back" +
              (workflow.currentStep?.progressive > 1 ? "" : " invisible")
            }
            data-element-id={getDataElementIdForBack()}
            data-description={getDataDescriptionForBack()}
            onClick={() => handleBack()}
          >
            <svg
              width="10"
              height="16"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.76439 15.9023L0.862061 7.99999L8.76439 0.0976562L9.23573 0.56899L1.80473 7.99999L9.23573 15.431L8.76439 15.9023Z"
                fill="#182446"
              />
            </svg>
          </div>
        </div>
        <div className={"MainHeader__center"}>
          <BrandComponent componentName="HeaderProgressBar" />
        </div>
        <div className={"MainHeader__right"}>
          <div
            className={"MainHeader__close"}
            data-element-id={getDataElementIdForClose()}
            onClick={() => reduxDispatch(modalsActions.setShowExitModal(true))}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L17 17" stroke="#182446" />
              <path d="M17 1L1 17" stroke="#182446" />
            </svg>
          </div>
        </div>
      </div>
    );
  };
  const renderMobile = () => {
    const configInsurance = useSelector(
      (state: any) => state.config?.insuranceModule
    );
    const insuranceEnabled = useSelector(
      (state: any) => state.insurance?.insuranceEnabled
    );
    const insuranceLoading = useSelector(
      (state: any) => state.insurance?.loading
    );
    const removeInsuranceFunction = useSelector(
      (state: any) => state.config?.insuranceModule?.removeInsuranceBenefits
    );
    const insuranceLabelRemove = useTranslate("insuranceLabelRemove");
    const insuranceSyncedLabel = useTranslate("insuranceSyncedLabel");
    const [isInsuranceEnabled, setIsInsuranceEnabled] = useState(false);
    const [isInsuranceLoading, setIsInsuranceLoading] = useState(false);

    const isInsurable = () => {
      return !!configInsurance;
    };

    useEffect(() => {
      setIsInsuranceEnabled(insuranceEnabled);
    }, [insuranceEnabled]);

    useEffect(() => {
      setIsInsuranceLoading(insuranceLoading);
    }, [insuranceLoading]);

    const handleInsuranceClick = () => {
      if (configInsurance) {
        if (!isInsuranceEnabled && configInsurance.openInsurancePanel) {
          configInsurance.openInsurancePanel();
        } else if (
          isInsuranceEnabled &&
          configInsurance.removeInsuranceBenefits
        ) {
          configInsurance.removeInsuranceBenefits();
        }
      }
    };

    return (
      <div
        className={
          "MainHeader__containerMobile" +
          (isInsuranceEnabled && removeInsuranceFunction
            ? " insuranceEnabled"
            : "")
        }
      >
        <BrandComponent componentName="HeaderProgressBar" />
        <div
          id={"MainHeader__content"}
          className={
            "MainHeader__content" +
            (isInsuranceEnabled && removeInsuranceFunction
              ? " insuranceEnabled"
              : "")
          }
        >
          <div
            className={
              "MainHeader__left" +
              (isInsuranceEnabled && removeInsuranceFunction
                ? " insuranceEnabled"
                : "")
            }
          >
            <div
              className={
                "MainHeader__left__back" +
                " " +
                (!currentStep || currentStep <= 1
                  ? "MainHeader__left__back_invisible"
                  : null)
              }
              onClick={() => handleBack()}
              data-element-id={getDataElementIdForBack()}
              data-description={getDataDescriptionForBack()}
            >
              <svg
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6465 23.8535L0.792969 12L12.6465 0.146484L13.3535 0.853484L2.20697 12L13.3535 23.1465L12.6465 23.8535Z"
                  fill="#182446"
                />
              </svg>
            </div>
          </div>
          <div
            id={"MainHeader__center"}
            className={
              "MainHeader__center" +
              (isInsuranceEnabled && removeInsuranceFunction
                ? " insuranceEnabled"
                : "")
            }
          >
            <BrandComponent componentName="HeaderPrice" />
            {isInsurable() && !isInsuranceEnabled && (
              <a
                className="MainHeader__insurance"
                onClick={handleInsuranceClick}
                data-element-id={getDataElementIdForInsurance()}
              >
                {addInsuranceLabel}
              </a>
            )}
            {isInsuranceEnabled && (
              <div
                className={"MainHeader__insurance__insuranceEnabled"}
                data-element-id={getDataElementIdForInsurance()}
              >
                {insuranceSyncedLabel}
                <BrandComponent
                  componentName="InsuranceTooltip"
                  parameter={{ id: "tooltipMobile", displayBelow: true }}
                />
              </div>
            )}
            {isInsuranceEnabled && removeInsuranceFunction && (
              <span
                className="MainHeader__removeInsuranceLabel"
                onClick={handleInsuranceClick}
              >
                {insuranceLabelRemove}
              </span>
            )}
          </div>
          <div
            className={
              "MainHeader__right" +
              (isInsuranceEnabled && removeInsuranceFunction
                ? " insuranceEnabled"
                : "")
            }
            data-element-id={getDataElementIdForClose()}
            onClick={() => reduxDispatch(modalsActions.setShowExitModal(true))}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L25 25" stroke="#182446" />
              <path d="M25 1L1 25" stroke="#182446" />
            </svg>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderDesktop()}
      {renderMobile()}
    </>
  );
}
