import React from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { formatDate, isMoreThanYear } from "../../utils/date";
import { BrandComponent } from "../common/BrandComponent";
import "./default.module.scss";

type Props = {
  prescription: {
    id: string;
    name: string;
    lastUpdate: string;
    expired: boolean;
    issueDate?: string;
  };
  onSelect: () => void;
  onShowPrescription: () => void;
};

export function PrescriptionFromMyAccountCard(props: Props) {
  const prescriptionNameLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.prescriptionName"
  );
  const uploadedOnLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.uploadedOn"
  );
  const showPrescriptionLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.showPrescription"
  );
  const olderThanYearBannerLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.olderThanYearBanner"
  );
  const expiredLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.expired"
  );
  const issueDateLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.issueDate"
  );
  return (
    <div
      className={`PrescriptionFromMyAccountCard${
        props.prescription.expired ? " expired" : ""
      }`}
      onClick={() => !props.prescription.expired && props.onSelect()}
    >
      {props.prescription.expired && <span>{expiredLabel}</span>}
      <p className="PrescriptionFromMyAccountCard__name">
        {prescriptionNameLabel}: {props.prescription.name}
      </p>
      {props.prescription.issueDate ? (
        <span className="PrescriptionFromMyAccountCard__issueDate">
          {issueDateLabel}:{" "}
          {formatDate(new Date(props.prescription.issueDate), "MM.YYYY")}
        </span>
      ) : (
        <BrandComponent
          componentName="LastUpdate"
          path="PrescriptionFromMyAccountCard/LastUpdate"
          parameter={{ lastUpdate: props.prescription.lastUpdate }}
        />
      )}
      <br />
      <p
        className="PrescriptionFromMyAccountCard__showPrescription"
        onClick={
          !props.prescription.expired
            ? (ev) => {
                props.onShowPrescription();
                ev.stopPropagation();
              }
            : () => {}
        }
      >
        {showPrescriptionLabel}
      </p>
      {!props.prescription.issueDate &&
        isMoreThanYear(new Date(), new Date(props.prescription.lastUpdate)) && (
          <p className="PrescriptionFromMyAccountCard__olderThanYearBanner">
            {olderThanYearBannerLabel}
          </p>
        )}
      <div className="PrescriptionFromMyAccountCard__separator"></div>
    </div>
  );
}
