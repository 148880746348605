import React from "react";
import "./default.module.scss";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { workflow } from "../../configurator/models/Workflow";
import { BrandComponent } from "../common/BrandComponent";
import { useCurrentPrices } from "@hooks";

interface HeaderPriceProps {}

/**
 * Component handling the price logic for the header in mobile view.
 */
export function HeaderPrice({}: HeaderPriceProps) {
  const currentPrices = useCurrentPrices();

  const priceFrameLabel = useTranslate("price.totalFrameLens.frame");
  const priceTotalLabel = useTranslate("price.totalFrameLens.frameLensTotal");

  return (
    <div id={"HeaderPrice__container"} className={"HeaderPrice__container"}>
      <div
        id={"HeaderPrice__container__label"}
        className={"HeaderPrice__container__label"}
      >
        {!workflow.hasManualSelectionBeenDone() ? (
          <span
            id={"HeaderPrice__container__label__price"}
            className={"HeaderPrice__container__label__price"}
          >
            <p> {priceFrameLabel}:</p>
          </span>
        ) : (
          <span
            id={"HeaderPrice__container__label__frameLensPrice"}
            className={"HeaderPrice__container__label__frameLensPrice"}
          >
            <p> {priceTotalLabel}:</p>
          </span>
        )}
      </div>
      <BrandComponent
        componentName="StepPrice"
        parameter={{
          price: currentPrices.total.price,
          discount: currentPrices.total.discount
            ? currentPrices.total.discount
            : null,
          insPrice: currentPrices.total.insPrice
            ? currentPrices.total.insPrice
            : null,
          hideSign: true,
          ignorePriceLabels: true,
          hideInsuranceLoader: true,
        }}
      />
    </div>
  );
}
