import { isFunction } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getStepSelections } from "../ConfigLoader";
import { useCurrentPackages } from "./useCurrentPackages";
import { useStepAlias } from "./useStepAlias";

export function useReviewSteps() {
  const reviewConfiguration = useSelector(
    (state: any) => state.config?.reviewConfiguration
  );
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  const reviewInsuranceObject = useSelector(
    (state: any) => state.insurance?.reviewInsuranceObject
  );
  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const pricingMethodConfig = useSelector(
    (state: any) => state.insurance?.pricingMethodConfig
  );
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const getAliasStep = useStepAlias();
  const [reviewSteps, setReviewSteps] = useState(null);
  const currentPackages = useCurrentPackages(true);
  const retriveInsPrice = (i1, i2) => {
    if (i1 !== null && i2 !== null) {
      return i1 + i2;
    } else if (i1 !== null && i2 == null) {
      return i1;
    } else if (i1 == null && i2 !== null) {
      return i2;
    }
    return null;
  };

  useEffect(() => {
    if (
      (pricingMethodConfig !== undefined || !insuranceEnabled) &&
      reviewConfiguration &&
      configContent &&
      isFunction(getAliasStep)
    ) {
      let steps = getStepSelections(getAliasStep, true, configContent);
      let mappedConfig = {};
      Object.keys(reviewConfiguration).map(
        (key) => (mappedConfig[key.toLowerCase()] = reviewConfiguration[key])
      );
      const thereIsTreatmentStep = steps.find(
        (step) => step.key == "treatments"
      );
      const thereIsTreatmentFamilyStep = steps.find(
        (step) => step.key == "treatmentsFamily"
      );
      //filter all treatments if the family is not defined
      steps = steps.filter((step) => {
        return !(
          step.key === "treatments" &&
          thereIsTreatmentStep &&
          thereIsTreatmentFamilyStep &&
          (!configContent.treatmentFamily ||
            Object.keys(configContent.treatmentFamily).length == 0)
        );
      });
      if (Object.keys(mappedConfig).length) {
        steps = steps.filter(
          (step) =>
            !step.attributes ||
            !step.attributes.skipped ||
            (step.attributes.skipped &&
              (!mappedConfig[step.key.toLowerCase()] ||
                mappedConfig[step.key.toLowerCase()] === "SHOW"))
        );
      }
      let colorStep = steps.find(
        (t) => t.originalStep == "TransitionColor" && t.price
      );
      if (colorStep) {
        steps = steps.map((s) => {
          if (s.originalStep == "TreatmentsFamily") {
            return {
              ...s,
              price: s.price + colorStep.price,
              discount: s.discount + colorStep.discount,
              insPrice: retriveInsPrice(s.insPrice, colorStep.insPrice),
            };
          } else if (colorStep.originalStep == s.originalStep) {
            let obj = {};
            Object.keys(s).forEach((k) => {
              if (!["price", "insPrice", "discount"].includes(k)) {
                obj[k] = s[k];
              }
            });
            return obj;
          }
          return s;
        });
      }
      if (reviewInsuranceObject) {
        let newInsPriceSetted = false;
        steps = steps.map((step) => {
          let newInsPrice = null;
          if (
            step.price === 0 &&
            (isNaN(step.insPrice) || step.insPrice === null)
          ) {
            newInsPrice = undefined;
          }
          if (
            step.price !== undefined &&
            reviewInsuranceObject[step.originalStep] &&
            !(
              step.insPrice !== undefined &&
              step.insPrice !== null &&
              !isNaN(step.insPrice)
            )
          ) {
            newInsPrice =
              step.price > 0
                ? configPackages.find(
                    (pkg) =>
                      pkg.lensPackage.catEntryId ===
                      reviewInsuranceObject[step.originalStep].catEntryId
                  ).lensPackage.insPrice
                : 0;
            newInsPriceSetted = true;
          } else {
            newInsPrice = step.insPrice;
          }
          return {
            ...step,
            insPrice: newInsPrice,
          };
        });
        let stepsWithPrice = steps
          .filter((step) => step.price !== undefined && step.price !== 0)
          .reverse();
        let correctInsPrices = {};
        stepsWithPrice.forEach((step, index) => {
          let nextStep =
            stepsWithPrice.length - 1 < index + 1
              ? null
              : stepsWithPrice[index + 1];
          if (
            !nextStep ||
            step.insPrice === undefined ||
            step.insPrice === null ||
            isNaN(step.insPrice)
          )
            return;
          correctInsPrices[step.originalStep] =
            step.insPrice - nextStep.insPrice;
        });
        if (newInsPriceSetted) {
          steps.forEach((step) => {
            if (correctInsPrices[step.originalStep] !== undefined) {
              if (step.originalStep === "ProtectionPlan") {
                step.insPrice = step.price;
              } else {
                step.insPrice = correctInsPrices[step.originalStep];
              }
            }
          });
        }
      }
      if (steps?.[0]?.value?.toLowerCase().includes("only")) {
        steps = steps.filter((step: any) => step?.key?.toLowerCase === "type");
      }

      setReviewSteps(steps);
    }
  }, [
    reviewConfiguration,
    configContent,
    getAliasStep,
    reviewInsuranceObject,
    pricingMethodConfig,
    insuranceEnabled,
    configPackages,
    currentPackages,
  ]);

  return reviewSteps;
}
