import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "../../redux/slices/modals";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { optionsSlider as OptionsSlider } from "../optionsSlider/component";
import { isMobile } from "react-device-detect";
import "./default.module.scss";

export function DigitalOptometryModal() {
  const showDigitalOptometryModal = useSelector(
    (state: any) => state.modals.showDigitalOptometryModal
  );
  const enableDigitalOptometry = useSelector(
    (state: any) => state.config.layoutSettings?.enableDigitalOptometry
  );
  const [section, setSection] = useState("app");
  const title = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.title"
  );
  const appSubtitle = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.appSubtitle"
  );
  const manuallySubtitle = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.manuallySubtitle"
  );
  const toggleApp = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.toggle.app"
  );
  const toggleManually = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.toggle.manually"
  );
  const opthyDesc = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.opthyDesc"
  );
  const manuallyContent = useTranslate(
    "steps.advancedPrescription.manual.digitalOptometry.manuallyContent"
  );
  const options = [
    {
      name: toggleApp,
      id: "app",
    },
    {
      name: toggleManually,
      id: "manually",
    },
  ];
  const dispatch = useDispatch();

  const handleToggle = (section: string) => {
    setSection(section);
  };
  const getSubtitle = () => {
    if (section === "app" && !isMobile) {
      return appSubtitle;
    } else {
      return manuallySubtitle;
    }
  };

  // useEffect(() => {
  //   if (showDigitalOptometryModal) {
  //     try {
  //       //@ts-ignore
  //       window.tealium_data2track.push({
  //         id: "Click",
  //         data_element_id: "X_X_MeasurePDOverlay_Manually",
  //         data_description: "AdvancedPrescription",
  //       });
  //       console.log("pushed");
  //     } catch (error) {
  //       console.log("error with tealium.push: " + error);
  //     }
  //   }
  // }, []);

  return (
    <div className="DigitalOptometryModal__container">
      <div
        className="DigitalOptometryModal__container__overlay"
        data-analytics_available_call="0"
        data-element-id="X_X_MeasurePDOverlay_Manually"
      />
      <div
        className="DigitalOptometryModal__container__box"
        data-analytics_available_call="0"
      >
        <div
          className="DigitalOptometryModal__container__box__close"
          onClick={() =>
            dispatch(
              modalsActions.setShowPrescriptionModalPD({
                show: false,
                modal: enableDigitalOptometry,
              })
            )
          }
        ></div>
        <div className="DigitalOptometryModal__container__box__title">
          {title}
        </div>
        <div className="DigitalOptometryModal__container__box__subtitle">
          {getSubtitle()}
        </div>
        <OptionsSlider
          slider={options}
          callback={(section: string) => handleToggle(section)}
        />
        <div className="DigitalOptometryModal__container__box__content">
          {section === "app" && (
            <>
              <div className="DigitalOptometryModal__container__box__content__iphoneImage"></div>
              <div className="DigitalOptometryModal__container__box__content__innerContainer">
                <div
                  className="DigitalOptometryModal__container__box__content__innerContainer__desc"
                  dangerouslySetInnerHTML={{
                    __html: opthyDesc,
                  }}
                />
                <div className="DigitalOptometryModal__container__box__content__innerContainer__images">
                  <div className="DigitalOptometryModal__container__box__content__innerContainer__images__qrCode" />
                  <div className="DigitalOptometryModal__container__box__content__innerContainer__images__appStore">
                    <p>
                      Problems with the QR code? See the app on the App Store.
                    </p>
                    <a
                      target="blank"
                      href="https://apps.apple.com/us/app/opthy/id1624663408"
                      className="DigitalOptometryModal__container__box__content__innerContainer__images__appStore__image"
                    ></a>
                  </div>
                </div>
              </div>
            </>
          )}
          {section === "manually" && (
            <div className="DigitalOptometryModal__container__box__content__manuallyContainer">
              <div className="DigitalOptometryModal__container__box__content__manuallyContainer__manuallyImage" />
              <div
                className="DigitalOptometryModal__container__box__content__manuallyContainer__context"
                dangerouslySetInnerHTML={{
                  __html: manuallyContent,
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
