import React from "react";
import { useCurrentPrices } from "../../configurator/hooks/useCurrentPrices";
import useActivateMoneySavingsBadge from "../../configurator/hooks/useActivateMoneySavingsBadge";
import usePriceDiscountLabel from "../../configurator/hooks/usePriceDiscountLabel";
import "./default.scss";

export interface DiscountBadgeProps {
  discount: number;
}

export function TotalPriceDiscountBadge(props: DiscountBadgeProps) {
  const discountBadge = useActivateMoneySavingsBadge();
  const savingLabel = usePriceDiscountLabel();
  const currentPrices = useCurrentPrices();

  return (
    <>
      {currentPrices.total.discount > 0 && discountBadge && (
        <div className="TotalPriceDiscountBadge__discountBadge">
          <p dangerouslySetInnerHTML={{ __html: savingLabel }} />
        </div>
      )}
    </>
  );
}
