import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  blueLightRule,
  cleanRule,
  sunRule,
  transitionRule,
} from "../ConfigLoader";
import { useAllCurrentPackages } from "./useAllCurrentPackages";
import { useGVPOptions } from "./useGVPOptions";
import { useTreatmentsFamilyMapper } from "./useTreatmentsFamilyMapper";

export function useExternalAutoselect(parameters: string[]) {
  const packages = useAllCurrentPackages();
  const externalSelectedPackage = useSelector(
    (state: any) => state.config.data?.lens?.catEntryId
  );
  const enablePreselect = useSelector(
    (state: any) => state.workflow?.loadPreselect
  );
  const loadingDynamicDiscounts = useSelector(
    (state: any) => state.workflow?.loadingDynamicDiscounts
  );

  const treatmentFamilyMapper = useTreatmentsFamilyMapper();
  const gvpTreatmentMapper = useGVPOptions();

  const [ret, setRet] = useState(undefined);

  useEffect(() => {
    if (
      packages &&
      treatmentFamilyMapper &&
      gvpTreatmentMapper &&
      enablePreselect != null &&
      loadingDynamicDiscounts === false
    ) {
      if (!enablePreselect) {
        setRet(null);
      } else {
        if (externalSelectedPackage) {
          const selectedPackage = packages.validRXPackages.find(
            (p) => p.lensPackage.catEntryId === externalSelectedPackage
          );
          if (selectedPackage && parameters) {
            let rett = {};
            parameters.forEach((par) => {
              if (par === "treatmentFamily") {
                let treatments = treatmentFamilyMapper(
                  packages,
                  selectedPackage
                );
                let caseS = null;
                if (cleanRule(selectedPackage.lensPackage)) {
                  caseS = "clear";
                } else if (transitionRule(selectedPackage.lensPackage)) {
                  caseS = "transition";
                } else if (blueLightRule(selectedPackage.lensPackage)) {
                  caseS = "blueLight";
                } else if (sunRule(selectedPackage.lensPackage)) {
                  caseS = "sun";
                }
                const finded = treatments.filter((t) => {
                  let extraCheck =
                    (caseS === "transition" || caseS === "blueLight") &&
                    t.id.indexOf("$") >= 0
                      ? selectedPackage.lensPackage[caseS]
                      : null;
                  if (extraCheck) {
                    //selecting the correct blueLight treatment
                    if (caseS === "blueLight") {
                      if (t?.id.indexOf(caseS) >= 0) {
                        let id = t?.id?.replace("blueLight$", "");
                        if (id === extraCheck) {
                          return id;
                        }
                      }
                    } else {
                      return (
                        t.id.indexOf(caseS) >= 0 &&
                        t.id.indexOf(extraCheck) >= 0
                      );
                    }
                  } else {
                    return t.id.indexOf(caseS) >= 0;
                  }
                });
                if (finded?.length) {
                  rett["treatmentFamily"] = finded?.[0]?.id;
                }
              } else if (par === "gvpTreatment") {
                let treatments = gvpTreatmentMapper(packages);
                let options = treatments?.options;
                if (options) {
                  const found = options.find(
                    (opt) =>
                      opt.id.indexOf(
                        selectedPackage.lensPackage.treatmentGroup
                      ) >= 0 &&
                      opt.id.indexOf(selectedPackage.lensPackage.treatment) >= 0
                  );
                  if (found) {
                    rett["gvpTreatment"] = found;
                  }
                }
              } else {
                rett[par] = selectedPackage.lensPackage[par];
              }
            });
            setRet(rett);
          } else {
            setRet(null);
          }
        } else {
          setRet(null);
        }
      }
    }
  }, [
    packages,
    externalSelectedPackage,
    treatmentFamilyMapper,
    gvpTreatmentMapper,
    enablePreselect,
    loadingDynamicDiscounts,
  ]);

  return ret;
}
