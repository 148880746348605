import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTransitionColors, useTransitionImage } from "@hooks";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { BrandComponent } from "../common/BrandComponent";
import Image from "../common/Image";
import "./default.module.scss";

interface LensPreviewTransitionOpt {
  lensImage?: string;
}

export function LensPreviewTransition(props: LensPreviewTransitionOpt) {
  const indoor = useTranslate("steps.transitionColor.indoor");
  const outdoor = useTranslate("steps.transitionColor.outdoor");
  const colorSelected = useSelector((state: any) => state.color.colorSelected);
  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );
  const [middleValue, setMiddleValue] = useState("50");
  const [currentOpacity, setCurrentOpacity] = useState(0.5);

  const { urlImage: transitionImages, isLoading } = useTransitionImage();

  const { isNestedTransitionSwatchEnabled } = useTransitionColors(null);

  const largeIconBasepath = useSelector(
    (state: any) =>
      `${state.config.baseURLs.assetsCDN || ""}/extra/image/rxc/icons/`
  );

  const handleChange = (e) => {
    let opacity = e.target.value / 100;
    setMiddleValue(e.target.value);
    setCurrentOpacity(opacity);
  };

  useEffect(() => {
    setMiddleValue("50");
  }, [colorSelected]);

  useEffect(() => {
    if (props.lensImage) {
      setCurrentOpacity(0.5);
    }
  }, [props.lensImage]);

  return isLoading ? (
    <BrandComponent componentName="Loader" parameter={{ greyLoader: true }} />
  ) : (
    <>
      <div
        className={`LensPreviewTransaction__container ${
          !transitionImages.frame && isNestedTransitionSwatchEnabled
            ? "fade-shadow"
            : ""
        }`}
      >
        {transitionImages.frame ? (
          <img
            src={transitionImages.frame}
            className={
              "LensPreviewTransaction__frame" +
              (!transitionImages.lens ? " no-absolute" : "")
            }
          />
        ) : isNestedTransitionSwatchEnabled ? (
          <Image
            imageClass="LensPreviewTransaction__white_img blend"
            url={largeIconBasepath}
            fileName={"rxc-icon-treatments-clear"}
          />
        ) : (
          <Image
            imageClass="LensPreviewTransaction__white_img"
            url={configProjectImage}
            fileName={"white-lens"}
          />
        )}
        {transitionImages.lens ? (
          <>
            <img
              src={
                transitionImages.lens /* "https://assets.lenscrafters.com/is/image/LensCrafters/713132838167__002.png" */
              }
              className="LensPreviewTransaction__lens"
              style={{ opacity: currentOpacity }}
            />
            {transitionImages.logo && (
              <img
                src={
                  transitionImages.logo /* "https://assets.lenscrafters.com/is/image/LensCrafters/713132838167__002.png" */
                }
                className={
                  "LensPreviewTransaction__frame" +
                  (!transitionImages.lens ? " no-absolute" : "")
                }
              />
            )}
          </>
        ) : !transitionImages.frame && !isNestedTransitionSwatchEnabled ? (
          <Image
            imageClass="LensPreviewTransaction__overlay"
            url={configProjectImage}
            fileName={props.lensImage}
            style={{ opacity: currentOpacity }}
          />
        ) : !transitionImages.frame && isNestedTransitionSwatchEnabled ? (
          <Image
            imageClass="LensPreviewTransaction__overlay blend"
            url={largeIconBasepath}
            fileName={props.lensImage}
            style={{ opacity: currentOpacity }}
          />
        ) : null}
      </div>
      {((transitionImages.frame &&
        transitionImages.lens &&
        !transitionImages.error) ||
        transitionImages.error) && (
        <div className="LensPreviewTransaction_range__container">
          <span className="LensPreviewTransaction_range__image_left">
            {indoor}
          </span>
          <input
            id="slider-opacity"
            aria-label="Lens color slider"
            className="lens-slider range opacity5"
            type="range"
            min="0"
            max="100"
            defaultValue={middleValue}
            value={middleValue}
            step="1"
            onChange={(e) => handleChange(e)}
            tabIndex={0}
          />
          <span className="LensPreviewTransaction_range__image_right">
            {outdoor}
          </span>
        </div>
      )}
    </>
  );
}
