import React, { useEffect } from "react";
import "./default.module.scss";
import { BrandComponent } from "../../../../docFiles";
import ReferencedSection from "../../../components/common/ReferencedSection";
import { workflow } from "../../models/Workflow";
import { workflowActions } from "../../../redux/slices/workflow";
import { insuranceActions } from "../../../redux/slices/insurance";
import { useTranslation } from "../../translations/TranslationHooks";
import { useDispatch, useSelector } from "react-redux";
import { useProtectionPlan } from "../../hooks/useProtectionPlan";
import {
  useCurrentType,
  useReleaseVersion,
  useRetriveContextualMessage,
  useWarrantyOptions,
} from "@hooks";
import { getFirstProduct } from "@hooks";

export const ProtectionPlan = () => {
  const translate = useTranslation();
  const reduxDispatch = useDispatch();
  const enableEPPStep = useProtectionPlan();
  const warrantyOptions = useWarrantyOptions();
  const ctxMessage = useRetriveContextualMessage();
  const currentType = useCurrentType();
  const configFrame = useSelector((state: any) => state.config?.data?.frame);
  const product = getFirstProduct() || {};
  const isEditMode = useSelector((state: any) => state.workflow?.isEditMode);
  const lens = useSelector((state: any) => state.config?.data?.lens);
  const warrantyId = useSelector(
    (state: any) => state.config?.data?.warranty?.catEntryId
  );
  const onProtectionSelected = (selectedProtectionId) => {
    const optionSelected = warrantyOptions.find(
      (wo: any) => wo.id === selectedProtectionId
    );
    reduxDispatch(insuranceActions.setWarranty(optionSelected));
    workflow.goNextStep(
      [
        {
          key: "protectionplan",
          value:
            optionSelected?.title || translate("steps.protectionplan.asIs"),
          price: optionSelected?.price,
          discount: optionSelected?.discount || 0,
          insPrice: optionSelected?.price,
        },
      ],
      warrantyOptions?.length > 1 ? false : true
    );
  };

  useEffect(() => {
    reduxDispatch(workflowActions.setLoadingStep(false));
  }, []);

  useEffect(() => {
    if (!enableEPPStep || warrantyOptions?.length === 1 || !warrantyOptions) {
      workflow.goNextStep([], true);
    }
  }, [enableEPPStep]);

  useEffect(() => {
    //edit from pdp or cart
    if (
      lens &&
      lens.catEntryId !== "" &&
      warrantyOptions &&
      warrantyOptions?.[0] &&
      !isEditMode
    ) {
      if (warrantyId) {
        const optionPreSelected = warrantyOptions.find(
          (wo: any) => wo.id === warrantyId
        );
        onProtectionSelected(optionPreSelected?.id);
      } else {
        //as is - no warranty
        onProtectionSelected(warrantyOptions[0]?.id);
      }
    }
  }, [lens, warrantyOptions, isEditMode, warrantyId]);

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: translate("steps.protectionplan.title"),
        subtitle: ctxMessage,
      }}
    >
      <BrandComponent
        componentName="SkipContainer"
        parameter={{
          stepName: "Thickness",
          onNext: (selected: any, skipped?: boolean) => {
            //onTypeSelected(selected?.id,skipped);
            if (!selected) {
              onProtectionSelected(null, skipped);
            } else {
              onProtectionSelected(selected, skipped);
            }
          },
          ignoreSkip: warrantyOptions && warrantyOptions.length > 1,
          parameterPreselection: ["protectionplan"],
          onPreselect: (selected, skipped: boolean) => {
            let protectionObj = {
              id: selected.protectionplan,
            };
            onProtectionSelected(protectionObj, skipped);
          },
        }}
      >
        {warrantyOptions
          ?.map((wo: any) => (
            <BrandComponent
              componentName="StepCard"
              parameter={{
                ...wo,
                onClick: (id) => onProtectionSelected(id),
                noImageCard: true,
                noMobileSpacer: wo.id === "asIs" ? false : true,
                ignoreInsurance: true,
                moreInfoTitle: translate("steps.protectionplan.whatsCovered"),
              }}
            />
          ))
          ?.reverse()}
      </BrandComponent>
      <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
    </BrandComponent>
  );
};
