import React, { FC } from "react";
import { LensColorConfig, LensProperty } from "../../../../types";
import { useTranslation } from "../../../translations/TranslationHooks";

type LensColorDetailsProps = {
  selectedColor: LensProperty;
  configColor: LensColorConfig[];
};

export const LensColorDetails: FC<LensColorDetailsProps> = ({
  selectedColor,
  configColor,
}) => {
  const translation = useTranslation();
  if (selectedColor && configColor) {
    let finded = (({
      title,
      longTitle,
      lightTransmission,
      baseLensColor,
      contrast,
      lightConditions,
    }) => ({
      title,
      longTitle,
      lightTransmission,
      baseLensColor,
      contrast,
      lightConditions,
    }))(configColor.find((c) => c.id === selectedColor.id));
    const { title, ...valuesToShow } = finded;
    const hasValues = Object.values(valuesToShow).some(
      (property) =>
        property !== null && property !== undefined && property !== 0
    );
    !Object.values(finded).every(
      (property) =>
        property !== null && property !== undefined && property !== 0
    ) && delete finded.title;
    return (
      <>
        {hasValues ? (
          <div className={"LensColor__btn_container__detailBox"}>
            <div className="LensColor__btn_container__detailBox__content">
              {Object.keys(finded).map((property, index) => {
                return (
                  finded[property] !== undefined &&
                  finded[property] !== null && (
                    <div
                      className={`LensColor__btn_container__detailBox__content__textContainer ${property}`}
                      style={{
                        order: index,
                      }}
                    >
                      {!["longTitle", "title"].includes(property) && (
                        <div
                          className={
                            "LensColor__btn_container__detailBox__content__textContainer__text"
                          }
                        >
                          {translation(`steps.lensColor.detailBox.${property}`)}
                          :
                        </div>
                      )}
                      <div
                        className={`LensColor__btn_container__detailBox__content__textContainer__inner ${
                          property === "longTitle" ? "description" : ""
                        }`}
                      >
                        {finded[property]}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        ) : null}
      </>
    );
  }
  return null;
};
