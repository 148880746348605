import React, { useState, useEffect } from "react";
import { useConfigContent } from "@hooks";
import { useSelector } from "react-redux";

export const useLensPreviewFallback = () => {
  const brand = useSelector((state: any) => state.config.data.frame.brand);
  const brandL = brand.toLowerCase();
  const [brandR, setBrandR] = useState(false);
  const getBrandCases = (brand) => {
    if (brand) {
      if (brand !== "oakley" && brand !== "costa" && brand !== "osi") {
        setBrandR(true);
      } else {
        setBrandR(false);
      }
    }
  };
  useEffect(() => {
    getBrandCases(brandL);
  }, [brand, brandL]);

  return {
    brandR,
  };
};
