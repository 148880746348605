export const configuration = {
  brand: "clearly",
  brandName: "Clearly",
  steps: [
    "Type",
    "AdvancedPrescription",
    "LensColor",
    "GVPTreatment",
    "DesignType",
    "Thickness",
    "Treatments",
    "AddOns",
    "ProtectionPlan",
    "Review",
  ],
  logoMap: {
    "Costa Del Mar": "LOGO01",
    "Ray-Ban_C": "LOGO02",
    "Ray-Ban_P": "LOGO03",
    "Ray-Ban": "LOGO04",
    Oakley: "LOGO05",
    Arnette: "LOGO08",
    Arnette_P: "LOGO09",
  },
  layoutSettings: {
    enableSendItLater: true,
    activateMoneySavingsBadge: false,
    delegatingLearnMore: false,
    enableDesignTypeStep: false,
    enableEPPStep: true,
    enableBrandLastStep: false,
    targetDigitalOptometry: [],
  },
  prescriptionModule: {
    enablePrismComment: false,
    enablePrism: true,
    mandatory: false,
    enableAgreementCheckbox: false,
  },
  prescriptionTypes: {
    SIMPLE: "SIMPLE",
    FULL: "FULL",
    NESTED_CA: "NESTED_CA",
  },
  skipConfiguration: {
    Type: "SELECT",
    LensColor: "SELECT",
  },
  reviewConfiguration: {
    Brand: "HIDE",
  },
  showLensPriceAtStep: "Thickness",
  //filterPromAtStep:'GVPTreatment',
  treatmentsOptions: ["clear", "blueLight", "transition"],
  addonsOptions: ["antiReflective", "antiScratch"],
  reviewOptions: ["blueLight", "uvProtection", "antiScratch", "antiReflective"],
  imageSrcsetTemplate:
    "{url}{filename}@2x.{format} 2x, {url}{filename}@3x.{format} 3x",
  imageFrameLayer:
    "##URL##/extra/image/rxc/frames/##MODEL##__##COLOR##__RXP__FRAME__qt.png",
  imageLensNoLogoLayer:
    "?impolicy=CompositeNoLogo&Lenses=##URL##/extra/image/rxc/lenses/##MODEL##__RXP__##CLEN##__qt.png",
  imageLensLogoLayer:
    "?impolicy=CompositeLogo&Lenses=##URL##/extra/image/rxc/lenses/##MODEL##__RXP__##CLEN##__qt.png",
  imageLens: "##URL##/extra/image/rxc/lenses/##MODEL##__RXP__##CLEN##__qt.png",
  imageLogo: "##URL##/extra/image/rxc/logo/##MODEL##__RXP__##LOGO##__qt.png",
  imageLogoLayer:
    "&Logo=##URL##/extra/image/rxc/logo/##MODEL##__RXP__##LOGO##__qt.png",
  imageTransitionLogo: "?impolicy=CompositeLogo&Lenses=",
  imageLensPreview:
    "##URL##/extra/image/rxc/lenspreview/LensPreview__RXP__##CLEN##__qt.png",
  baseDAMwithLogo:
    "###FRAME###?impolicy=CompositeLogo&Lenses=###LENS###&Logo=###LOGO###",
  baseDAMwithoutLogo: "###FRAME###?impolicy=CompositeNoLogo&Lenses=###LENS###",
  baseURLDAMLOGO: "https://lcclabapip01.azure-api.net/records-roxlogo/_search",
  baseURLDAMLENS: "https://lcclabapip01.azure-api.net/records-roxlens/_search",
  baseURLDAMFRAME: "https://lcclabapip01.azure-api.net/records-pi/_search",
  headerDAMapi: {
    "Ocp-Apim-Subscription-Key": "f5a17e10c75e44cabb7e6ad526a46178",
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "*/*",
    Host: "lcclabapip01.azure-api.net",
    "Accept-Encoding": "gzip, deflate, br",
    Connection: "keep-alive",
  },
  logoDAMbody: {
    _source: {
      includes: [
        "fields.featuredStyleModel",
        "fields.logoCode",
        "fields.name",
        "classifications.identifier",
        "masterFileLatestVersionName",
        "masterFileLatestVersionPath",
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              "fields.featuredStyleModel": "0RB3025",
            },
          },
        ],
      },
    },
  },
  lensDAMbody: {
    _source: {
      includes: [
        "fields.featuredStyleModel",
        "fields.clenCode",
        "fields.name",
        "classifications.identifier",
        "masterFileLatestVersionName",
        "masterFileLatestVersionPath",
      ],
    },
    size: 1000,
    query: {
      bool: {
        must: [
          {
            match: {
              "fields.featuredStyleModel": "0RB3025",
            },
          },
        ],
      },
    },
  },
  frameDAMBody: {
    _source: {
      includes: [
        // optional, the list of required fields
        "indexedOnUtc",
        "fields.name",
        "fields.featuredStyleModel",
        "fields.featuredColorColorCode",
        "fields.upcCode",
        "fields.collection",
        "fileCount",
        "fields.isModelColorPrimary",
      ],
    },
    from: 0, // zero-indexed offset from the beginning of the complete result set that indicates the document you want to start with.
    size: 1, // the number of matching assets to return.
    query: {
      bool: {
        must: [
          {
            nested: {
              path: "files",
              query: {
                bool: {
                  must: [
                    //Check existance of images with Image Format "Frame"
                    {
                      term: {
                        "files.fields.imageFormat": "Frame",
                      },
                    },
                  ],
                },
              },
              inner_hits: {},
            },
          },
          {
            match: {
              // optional, filter by Model
              "fields.featuredStyleModel": "0RB3025",
            },
          },
          {
            match: {
              // optional, filter by Color
              "fields.featuredColorColorCode": "002/58",
            },
          },
          {
            nested: {
              path: "classifications",
              query: {
                bool: {
                  should: [
                    {
                      term: {
                        "classifications.identifier":
                          "Tech_ProductImages_Restricted_Usage_Date_Requirement_Bypassed_B2B",
                      },
                    },
                    {
                      term: {
                        "classifications.identifier":
                          "Tech_ProductImages_Restricted_Usage_Date_Requirement_Bypassed_B2C",
                      },
                    },
                    {
                      term: {
                        "classifications.identifier":
                          "Tech_ProductImages_Restricted_Usage_minAP-FSO_Date_Approaching",
                      },
                    },
                  ],
                },
              },
            },
          },
        ],
        must_not: [
          {
            nested: {
              path: "classifications",
              query: {
                bool: {
                  must: [
                    {
                      term: {
                        "classifications.identifier": "Status_Hidden",
                      },
                    },
                  ],
                },
              },
            },
          },
          {
            nested: {
              path: "classifications",
              query: {
                bool: {
                  must: [
                    {
                      term: {
                        "classifications.identifier": "Status_Expired",
                      },
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    },
  },
  prescriptionDefaults: {
    sphMin: -20,
    sphMax: 20,
    sphRes: 0.25,
    cylMin: -20,
    cylMax: 20,
    cylRes: 0.25,
    addMin: 0.75,
    addMax: 4,
    addRes: 0.25,
    pdHalfMin: 25,
    pdHalfMax: 38,
    pdHalfRes: 1,
    pdMin: 51,
    pdMax: 77,
    pdRes: 1,
    pdSphRangeMin: -3,
    pdSphRangeMax: 3,
    prismVertMin: 0.5,
    prismVertMax: 5,
    prismVertRes: 0.25,
    prismBaseVert: ["Up", "Down"],
    prismHorMin: 0.5,
    prismHorMax: 5,
    prismHorRes: 0.25,
    prismBaseHor: ["In", "Out"],
  },
  baseURLs: {
    genericImage:
      "https://assets.lenscrafters.com/extra/image/LensCrafters/global/",
    projectImage: "https://assets.lenscrafters.com/extra/image/rxc/images/",
    framesImage: "https://assets.lenscrafters.com/is/image/LensCrafters",
    extraImages: "https://images.ray-ban.com/extra/image/rxc/brands/RayBan",
    assetsCDN: "https://assets.lenscrafters.com",
    contextualMessages:
      "https://assets.lenscrafters.com/extra/image/rxc/ctxmsg",
    RIAConfig:
      "https://assets.lenscrafters.com/extra/image/rxc/ria/settings.json",
    learnMoreBaseEndpoint: "/wcs/resources/store/10851/espot/",
  },
  currencyFormat: {
    thousandSeparator: ",",
    decimalSeparator: ".",
    prefix: "$",
  },
  translation: {
    language: "en_US",
  },
  linksData: {
    warrantyLearnMore: "/lc-us/purchase-care/details",
  },
  paymentInstallmentsTypes: {
    AFFIRM: "affirm",
  },
  paymentInstallment: {
    installments: 4,
  },
  RIAFlowConfiguration: {
    sunglasses: {
      Type: {
        prev: null,
        key: "type",
        next: [
          {
            step: "Brand",
          },
        ],
      },
      Brand: {
        prev: "Type",
        key: "brand",
        next: [
          {
            step: "LensColor",
          },
        ],
      },
      LensColor: {
        prev: "Brand",
        key: "color",
        next: [
          {
            step: "Thickness",
          },
        ],
      },
      Thickness: {
        prev: "LensColor",
        key: "thickness",
        next: [
          {
            step: "AddOns",
          },
        ],
      },
      AddOns: {
        prev: "Thickness",
        key: "antiReflective",
        next: null,
      },
      Review: {
        prev: "AddOns",
        key: null,
        next: null,
      },
    },
    sunglassesGVP: {
      Type: {
        prev: null,
        key: "type",
        next: [
          {
            step: "Brand",
          },
        ],
      },
      Brand: {
        prev: "Type",
        key: "brand",
        next: [
          {
            step: "LensColor",
          },
        ],
      },
      LensColor: {
        prev: "Brand",
        key: "color",
        next: [
          {
            step: "GVPTreatment",
          },
        ],
      },
      GVPTreatment: {
        prev: "LensColor",
        key: null,
        next: [
          {
            key: null,
            exclude: ["blueLight", "transition", "color"],
            step: "Thickness",
          },
          {
            key: "blueLight",
            exclude: ["transition"],
            step: "Thickness",
          },
        ],
      },
      Thickness: {
        prev: "GVPTreatment",
        key: "thickness",
        next: [
          {
            step: "AddOns",
          },
        ],
      },
      AddOns: {
        prev: "Thickness",
        key: "antiReflective",
        next: null,
      },
      Review: {
        prev: "AddOns",
        key: null,
        next: null,
      },
    },
    eyeFrameOnly: {
      Type: {
        prev: null,
        key: "type",
        next: null,
      },
      Review: {
        prev: "Type",
        key: null,
        next: null,
      },
    },
    sunFrameOnly: {
      Type: {
        prev: null,
        key: "type",
        next: null,
      },
      Review: {
        prev: "Type",
        key: null,
        next: null,
      },
    },
  },
  lensMock: {
    took: 5,
    timed_out: false,
    _shards: {
      total: 1,
      successful: 1,
      skipped: 0,
      failed: 0,
    },
    hits: {
      total: {
        value: 31,
        relation: "eq",
      },
      max_score: 0.0111732995,
      hits: [
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "35ac3739-5ab5-4800-ab14-ae6700da5e08",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/35ac3739-5ab5-4800-ab14-ae6700da5e08/0RB3025__RXP__CLEN__Polar_Grey_Gradient__qt.png",
            masterFileLatestVersionName:
              "0RB3025__RXP__CLEN__Polar_Grey_Gradient__qt.png",
            fields: {
              clenCode: "Polar_Grey_Gradient",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__Polar_Grey_Gradient__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "9afcedcb-a726-49fe-9296-ae6700da5a3c",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/9afcedcb-a726-49fe-9296-ae6700da5a3c/0RB3025__RXP__CLEN__Solid_Grey__qt.png",
            masterFileLatestVersionName:
              "0RB3025__RXP__CLEN__Solid_Grey__qt.png",
            fields: {
              clenCode: "Solid_Grey",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__Solid_Grey__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "fd6d8bb7-b090-49ca-87bc-ae6700da564b",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/fd6d8bb7-b090-49ca-87bc-ae6700da564b/0RB3025__RXP__CLEN__Solid_Grey_Polar__qt.png",
            masterFileLatestVersionName:
              "0RB3025__RXP__CLEN__Solid_Grey_Polar__qt.png",
            fields: {
              clenCode: "Solid_Grey_Polar",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__Solid_Grey_Polar__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "18b334c7-c35c-4bf7-9b6b-ae6700da692f",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/18b334c7-c35c-4bf7-9b6b-ae6700da692f/0RB3025__RXP__CLEN__Polar_Green_Grey__qt.png",
            masterFileLatestVersionName:
              "0RB3025__RXP__CLEN__Polar_Green_Grey__qt.png",
            fields: {
              clenCode: "Polar_Green_Grey",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__Polar_Green_Grey__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "d06be8d6-8900-40a8-b0e9-ae6700da7104",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/d06be8d6-8900-40a8-b0e9-ae6700da7104/0RB3025__RXP__CLEN__Polar_Brown_Grey_Gradient__qt.png",
            masterFileLatestVersionName:
              "0RB3025__RXP__CLEN__Polar_Brown_Grey_Gradient__qt.png",
            fields: {
              clenCode: "Polar_Brown_Grey_Gradient",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__Polar_Brown_Grey_Gradient__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "43d53e00-3f53-4b4f-821d-ae6700da74c3",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/43d53e00-3f53-4b4f-821d-ae6700da74c3/0RB3025__RXP__CLEN__Polar_Brown_Gradient__qt.png",
            masterFileLatestVersionName:
              "0RB3025__RXP__CLEN__Polar_Brown_Gradient__qt.png",
            fields: {
              clenCode: "Polar_Brown_Gradient",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__Polar_Brown_Gradient__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "39c00ce4-f175-49e4-9949-ae6700da6537",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/39c00ce4-f175-49e4-9949-ae6700da6537/0RB3025__RXP__CLEN__Solid_Brown_Polar__qt.png",
            masterFileLatestVersionName:
              "0RB3025__RXP__CLEN__Solid_Brown_Polar__qt.png",
            fields: {
              clenCode: "Solid_Brown_Polar",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__Solid_Brown_Polar__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "72473b3f-caa5-49de-b30d-ae6700da6d24",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/72473b3f-caa5-49de-b30d-ae6700da6d24/0RB3025__RXP__CLEN__Polar_Brown__qt.png",
            masterFileLatestVersionName:
              "0RB3025__RXP__CLEN__Polar_Brown__qt.png",
            fields: {
              clenCode: "Polar_Brown",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__Polar_Brown__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "f92cbd39-8887-4ded-b93b-ae6700da7b67",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/f92cbd39-8887-4ded-b93b-ae6700da7b67/0RB3025__RXP__CLEN__L877C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__L877C__qt.png",
            fields: {
              clenCode: "L877C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__L877C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "a026e7b8-88f9-4079-8624-ae6700da7f68",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/a026e7b8-88f9-4079-8624-ae6700da7f68/0RB3025__RXP__CLEN__Polar_Blue__qt.png",
            masterFileLatestVersionName:
              "0RB3025__RXP__CLEN__Polar_Blue__qt.png",
            fields: {
              clenCode: "Polar_Blue",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__Polar_Blue__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "65d7933f-87d0-4a9a-8efc-ae6700da8f94",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/65d7933f-87d0-4a9a-8efc-ae6700da8f94/0RB3025__RXP__CLEN__L682C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__L682C__qt.png",
            fields: {
              clenCode: "L682C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__L682C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "d7a67405-ca12-4d9c-9e8d-ae6700dab7f5",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/d7a67405-ca12-4d9c-9e8d-ae6700dab7f5/0RB3025__RXP__CLEN__L516C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__L516C__qt.png",
            fields: {
              clenCode: "L516C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__L516C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "55dfa4fb-f774-4cbe-a47c-ae6700da61bf",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/55dfa4fb-f774-4cbe-a47c-ae6700da61bf/0RB3025__RXP__CLEN__Solid_Brown__qt.png",
            masterFileLatestVersionName:
              "0RB3025__RXP__CLEN__Solid_Brown__qt.png",
            fields: {
              clenCode: "Solid_Brown",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__Solid_Brown__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "c16fe367-b296-40c6-9bad-ae6700da82e1",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/c16fe367-b296-40c6-9bad-ae6700da82e1/0RB3025__RXP__CLEN__L680C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__L680C__qt.png",
            fields: {
              clenCode: "L680C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__L680C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "4abda7d7-fdba-44a4-bcbe-ae6700dabb0b",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/4abda7d7-fdba-44a4-bcbe-ae6700dabb0b/0RB3025__RXP__CLEN__L586C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__L586C__qt.png",
            fields: {
              clenCode: "L586C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__L586C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "a0b39163-5ab0-4550-ac17-ae6700dacfba",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/a0b39163-5ab0-4550-ac17-ae6700dacfba/0RB3025__RXP__CLEN__L020C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__L020C__qt.png",
            fields: {
              clenCode: "L020C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__L020C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "d3608e28-cd61-41c7-8401-ae6700dac3ff",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/d3608e28-cd61-41c7-8401-ae6700dac3ff/0RB3025__RXP__CLEN__L642C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__L642C__qt.png",
            fields: {
              clenCode: "L642C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__L642C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "5deed148-e070-40da-8d12-ae6700dadb6f",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/5deed148-e070-40da-8d12-ae6700dadb6f/0RB3025__RXP__CLEN__L515C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__L515C__qt.png",
            fields: {
              clenCode: "L515C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__L515C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "fac249f2-694a-4eb0-baaf-ae6700dae746",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/fac249f2-694a-4eb0-baaf-ae6700dae746/0RB3025__RXP__CLEN__L019C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__L019C__qt.png",
            fields: {
              clenCode: "L019C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__L019C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "5283724e-5441-423c-8f66-ae6700daf315",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/5283724e-5441-423c-8f66-ae6700daf315/0RB3025__RXP__CLEN__Brown_Dark_Brown_Gradient__qt.png",
            masterFileLatestVersionName:
              "0RB3025__RXP__CLEN__Brown_Dark_Brown_Gradient__qt.png",
            fields: {
              clenCode: "Brown_Dark_Brown_Gradient",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__Brown_Dark_Brown_Gradient__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "7cad71a9-8f45-4cd5-99e7-ae6700dafe96",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/7cad71a9-8f45-4cd5-99e7-ae6700dafe96/0RB3025__RXP__CLEN__Green_Grey__qt.png",
            masterFileLatestVersionName:
              "0RB3025__RXP__CLEN__Green_Grey__qt.png",
            fields: {
              clenCode: "Green_Grey",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__Green_Grey__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "009a3e59-9af9-4e26-88cb-ae6700db0a4e",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/009a3e59-9af9-4e26-88cb-ae6700db0a4e/0RB3025__RXP__CLEN__2467I__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__2467I__qt.png",
            fields: {
              clenCode: "2467I",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__2467I__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "31d32860-8097-4b17-9afd-ae6700db160f",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/31d32860-8097-4b17-9afd-ae6700db160f/0RB3025__RXP__CLEN__Blue__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__Blue__qt.png",
            fields: {
              clenCode: "Blue",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__Blue__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "cff91801-d5d0-4f43-af46-ae6700db21c1",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/cff91801-d5d0-4f43-af46-ae6700db21c1/0RB3025__RXP__CLEN__2424I__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__2424I__qt.png",
            fields: {
              clenCode: "2424I",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__2424I__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "c3a1d7cf-a01c-4211-ac04-ae6700db2d75",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/c3a1d7cf-a01c-4211-ac04-ae6700db2d75/0RB3025__RXP__CLEN__2422I__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__2422I__qt.png",
            fields: {
              clenCode: "2422I",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__2422I__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "f1a91fb6-ff7e-497a-b7dc-ae6700db3935",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/f1a91fb6-ff7e-497a-b7dc-ae6700db3935/0RB3025__RXP__CLEN__0645C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__0645C__qt.png",
            fields: {
              clenCode: "0645C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__0645C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "cc4c6847-8cab-48dc-a7a6-ae6700db44e5",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/cc4c6847-8cab-48dc-a7a6-ae6700db44e5/0RB3025__RXP__CLEN__0659C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__0659C__qt.png",
            fields: {
              clenCode: "0659C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__0659C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "68e0017a-391d-419c-8c15-ae6700db509c",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/68e0017a-391d-419c-8c15-ae6700db509c/0RB3025__RXP__CLEN__0633C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__0633C__qt.png",
            fields: {
              clenCode: "0633C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__0633C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "9004f4d5-43c6-4ceb-9776-ae6700db5c59",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/9004f4d5-43c6-4ceb-9776-ae6700db5c59/0RB3025__RXP__CLEN__0618C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__0618C__qt.png",
            fields: {
              clenCode: "0618C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__0618C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "bb766385-e90f-4bbb-8290-ae6700db680d",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/bb766385-e90f-4bbb-8290-ae6700db680d/0RB3025__RXP__CLEN__0058C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__0058C__qt.png",
            fields: {
              clenCode: "0058C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__0058C__qt",
            },
          },
        },
        {
          _index: "records-roxlens-2021-12-03-14-26-01",
          _type: "_doc",
          _id: "4a683eb0-7610-4e1d-a555-ae6700db73ca",
          _score: 0.0111732995,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLensCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlens/4a683eb0-7610-4e1d-a555-ae6700db73ca/0RB3025__RXP__CLEN__0060C__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__CLEN__0060C__qt.png",
            fields: {
              clenCode: "0060C",
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__CLEN__0060C__qt",
            },
          },
        },
      ],
    },
  },
  logoMock: {
    took: 2,
    timed_out: false,
    _shards: {
      total: 1,
      successful: 1,
      skipped: 0,
      failed: 0,
    },
    hits: {
      total: {
        value: 2,
        relation: "eq",
      },
      max_score: 0.18232156,
      hits: [
        {
          _index: "records-roxlogo-2021-12-03-14-26-09",
          _type: "_doc",
          _id: "dcf820fe-1fb4-4e2f-bc5e-ae6700c8832b",
          _score: 0.18232156,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLogoCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlogo/dcf820fe-1fb4-4e2f-bc5e-ae6700c8832b/0RB3025__RXP__LOGO03__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__LOGO03__qt.png",
            fields: {
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__LOGO03__qt",
              logoCode: "03",
            },
          },
        },
        {
          _index: "records-roxlogo-2021-12-03-14-26-09",
          _type: "_doc",
          _id: "145eebf4-6804-4f8f-8a28-ae6700c88753",
          _score: 0.18232156,
          _source: {
            classifications: [
              {
                identifier: "LuxotticaDAM",
              },
              {
                identifier: "Status",
              },
              {
                identifier: "Status_Approved",
              },
              {
                identifier: "Brand",
              },
              {
                identifier: "MacroBrandRay-Ban",
              },
              {
                identifier: "RB",
              },
              {
                identifier: "ROX-Related",
              },
              {
                identifier: "Category",
              },
              {
                identifier: "ROXComponentsCategory",
              },
              {
                identifier: "ROXLogoCategory",
              },
            ],
            masterFileLatestVersionPath:
              "/cdn-record-files/roxlogo/145eebf4-6804-4f8f-8a28-ae6700c88753/0RB3025__RXP__LOGO04__qt.png",
            masterFileLatestVersionName: "0RB3025__RXP__LOGO04__qt.png",
            fields: {
              featuredStyleModel: "0RB3025",
              name: "0RB3025__RXP__LOGO04__qt",
              logoCode: "04",
            },
          },
        },
      ],
    },
  },
  frameMock: {
    took: 9,
    timed_out: false,
    _shards: {
      total: 1,
      successful: 1,
      skipped: 0,
      failed: 0,
    },
    hits: {
      total: {
        value: 1,
        relation: "eq",
      },
      max_score: 24.982178,
      hits: [
        {
          _index: "records-pi-2022-05-07-03-46-41",
          _type: "_doc",
          _id: "f2a2b356-cf47-4e4c-bd0e-a35900057b03",
          _score: 24.982178,
          _source: {
            indexedOnUtc: "2022-05-17T14:31:56.5063241Z",
            fields: {
              featuredStyleModel: "0RB3025",
              isModelColorPrimary: "False",
              name: "0RB3025 L0205",
              featuredColorColorCode: "L0205",
              collection: "200204-REG",
              upcCode: ["00805289602057", "00805289328063", "00805289001270"],
            },
            fileCount: 36,
          },
          inner_hits: {
            files: {
              hits: {
                total: {
                  value: 1,
                  relation: "eq",
                },
                max_score: 3.4688873,
                hits: [
                  {
                    _index: "records-pi-2022-05-07-03-46-41",
                    _type: "_doc",
                    _id: "f2a2b356-cf47-4e4c-bd0e-a35900057b03",
                    _nested: {
                      field: "files",
                      offset: 35,
                    },
                    _score: 3.4688873,
                    _source: {
                      versionCount: 1,
                      latestVersionName: "0RB3025__L0205__RXP__FRAME__qt.png",
                      sortIndex: 35,
                      isMaster: false,
                      latestVersionPath:
                        "/cdn-record-files-pi/f2a2b356-cf47-4e4c-bd0e-a35900057b03/0bb1af17-c8f4-4690-af2a-ae6700c39bcf/0RB3025__L0205__RXP__FRAME__qt.png",
                      id: "0bb1af17-c8f4-4690-af2a-ae6700c39bcf",
                      createdOnUtc: "2022-03-29T11:52:11.3570000Z",
                      fields: {
                        imageFormat: "Frame",
                        productImageType: "Quarter",
                        width: 3768,
                        packageType: "ROX",
                        degrees: 30.0,
                        height: 1884,
                      },
                      fileVersions: [
                        {
                          fileName: "0RB3025__L0205__RXP__FRAME__qt.png",
                          extension: "png",
                          isLatestVersion: true,
                          fileCreatedOnUtc: "2022-03-29T11:50:14.4830000Z",
                          filesize: 734040,
                          crc32: -1327242194,
                          id: "a94fd681-0918-4d88-af2b-ae6700c39bcf",
                          createdOnUtc: "2022-03-29T11:52:11.3570000Z",
                          versionNumber: 1,
                          fileModifiedOnUtc: "2022-03-29T11:49:36.9730000Z",
                        },
                      ],
                    },
                  },
                ],
              },
            },
          },
        },
      ],
    },
  },
};
