import React from "react";
import { workflow } from "../../configurator/models/Workflow";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { BrandComponent } from "../common/BrandComponent";
import "./default.module.scss";
import { isShowFramePlusLensesPrice } from "../../configurator/ConfigLoader";

interface BottomBarTotalPriceProps {
  total: { price: number; discount: number; insPrice?: number };
}

/**
 * Small component that displays the total price and the gst in the bottom bar. It accepts
 * an input of type ```BottomBarTotalPriceProps``` which is an interface defining one
 * parameter:
 *
 * ```typescript
 * {
 *      total: { price: number, discount: number }
 * }
 * ```
 *
 * The component displays these prices using the ```Price``` component.
 */
export function BottomBarTotalPrice({ total }: BottomBarTotalPriceProps) {
  const priceFrameLabel = useTranslate("price.totalFrameLens.frame");
  const priceTotalLabel = useTranslate("price.totalFrameLens.frameLensTotal");
  const taxLabel = useTranslate("tax");
  const isShow = isShowFramePlusLensesPrice();
  return (
    <div className={"BottomBarTotalPrice"}>
      <div className={"BottomBarTotalPrice__pricerow"}>
        <div className={"BottomBarTotalPrice__pricerow__label"}>
          {!workflow.hasManualSelectionBeenDone() ? (
            isShow ? (
              <p> {priceFrameLabel}:</p>
            ) : (
              <></>
            )
          ) : isShow ? (
            <p> {priceTotalLabel}:</p>
          ) : (
            <></>
          )}
        </div>
        <div className={"BottomBarTotalPrice__pricerow__price"}>
          {isShow ? (
            <BrandComponent
              componentName="StepPrice"
              parameter={{
                price: total.price,
                discount: total.discount ? total.discount : null,
                insPrice:
                  total.insPrice || total.insPrice === 0
                    ? total.insPrice
                    : null,
                priceStyle: { fontSize: 18 },
                hideSign: true,
                ignorePriceLabels: true,
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={"BottomBarTotalPrice__gstrow"}>{taxLabel}</div>
    </div>
  );
}
