import React, { useEffect, useState } from "react";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import "./default.module.scss";

interface FullPageProps {
  children: ReactNode;
}

export function FullPage({ children }: FullPageProps) {
  const loadPreselectEnabled = useSelector(
    (state: any) => state.workflow?.loadPreselect
  );
  const [hideContent, setHideContent] = useState(loadPreselectEnabled);

  useEffect(() => {
    setHideContent(loadPreselectEnabled);
  }, [loadPreselectEnabled]);

  return (
    <div className={"FullPage_root"} /* style={hideContent?{opacity:0}:null} */>
      {children}
    </div>
  );
}
