import React from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";
import { getSimplePrescriptionValuesArray } from "../../../utils/prescription";
import { BrandComponent } from "../../common/BrandComponent";
import utils from "../utils";

export type FormTableProps = {
  cylOD;
  cylOS;
  columns;
  isAddDisabled;
  onSubmit;
  formErrors;
  selectPlaceholder;
  selector;
  formValues;
};

export const FormTable = (props: FormTableProps) => {
  const prismSelectPlaceholder = "-";
  const rightEyeInitials = useTranslate(
    "steps.advancedPrescription.manual.rightEye.initials"
  );
  const leftEyeInitials = useTranslate(
    "steps.advancedPrescription.manual.leftEye.initials"
  );
  const rightEyeName = useTranslate(
    "steps.advancedPrescription.manual.rightEye.name"
  );
  const leftEyeName = useTranslate(
    "steps.advancedPrescription.manual.leftEye.name"
  );
  const isAxisDisabled = (id: string) => {
    if (id.indexOf("right") >= 0) {
      return (
        props.cylOD === null || props.cylOD === undefined || props.cylOD === 0
      );
    } else {
      return (
        props.cylOS === null || props.cylOS === undefined || props.cylOS === 0
      );
    }
  };

  const disabledAxisLabel = (row: any) => {
    let res = true;
    row.map((el) => {
      if (el.id.indexOf("right") >= 0 || el.id.indexOf("left")) {
        return (res =
          props.cylOD === null ||
          props.cylOD === undefined ||
          props.cylOD === 0);
      }
    });
    return res;
  };
  return (
    <div className={"FullPrescriptionForm__innerContainer__form_table"}>
      {isDesktop && (
        <div style={{ display: "flex", flexWrap:"wrap" }}>
          <div className={"FullPrescriptionForm__innerContainer__form_leftcol"}>
            <div
              className={"FullPrescriptionForm__innerContainer__form_leftlabel"}
            >
              <div>{rightEyeInitials}</div>
              <div>{rightEyeName}</div>
            </div>
            <div
              className={"FullPrescriptionForm__innerContainer__form_leftlabel"}
            >
              <div>{leftEyeInitials}</div>
              <div>{leftEyeName}</div>
            </div>
          </div>
          {props.columns.map((col) => (
            <div
              key={col.id}
              className={
                "FullPrescriptionForm__innerContainer__form_col" +
                (col.id === "add" && props.isAddDisabled()
                  ? " invisible"
                  : "") +
                (col.id === "axis" && disabledAxisLabel(col.rows)
                  ? " disabled--text"
                  : "") +
                (props.isAddDisabled()
                  ? " maxWidthWithoutADD"
                  : " maxWidthIsADD")
              }
            >
              <div className={"FullPrescriptionForm__innerContainer__form_row"}>
                {col.name}
              </div>

              {col.rows.map((row) =>
                props.selector === "prescription" ? (
                  <div
                    key={row.id}
                    className={"FullPrescriptionForm__innerContainer__form_row"}
                  >
                    {col.id === "add" && (
                      <div
                        id={row.id}
                        className={
                          "FullPrescriptionForm__innerContainer__form_select"
                        }
                      >
                        <div
                          className={`FullPrescriptionForm__innerContainer__form_select_prescription${
                            props.formErrors[row.id] ? "__error" : ""
                          } FullPrescriptionForm__innerContainer__form_select_prescription__add`}
                        >
                          <select
                            tabIndex={0}
                            title={row.id}
                            name={row.id}
                            id={row.id}
                            value={
                              props.formValues[props.selector][row.id].value ||
                              ""
                            }
                            onChange={(e) => {
                              let valueToSet =
                                col.id === "baseDirectionVert" ||
                                col.id === "baseDirectionHor"
                                  ? e.currentTarget.value
                                  : parseFloat(e.currentTarget.value);
                              props.formValues[props.selector][row.id].setter(
                                valueToSet
                              );
                            }}
                          >
                            {getSimplePrescriptionValuesArray(
                              col.min,
                              col.max,
                              col.res,
                              col.id === "add"
                                ? props.selectPlaceholder
                                : props.selector === "prism"
                                ? prismSelectPlaceholder
                                : null,
                              2,
                              col.values
                            ).map((e, key) => {
                              return (
                                <option key={key} value={e.value}>
                                  {e.name}
                                </option>
                              );
                            })}
                          </select>
                          {/**TODO Only visible for Costa  */}
                          <div
                            className={
                              "FullPrescriptionForm__innerContainer__form_select__image"
                            }
                          ></div>
                        </div>
                      </div>
                    )}
                    {col.id !== "axis" && col.id !== "add" && (
                      <div
                        id={row.id}
                        className={
                          "FullPrescriptionForm__innerContainer__form_select_custom"
                        }
                      >
                        <BrandComponent
                          componentName={"PrescriptionSelect"}
                          parameter={{
                            name: row.id,
                            id: row.id,
                            value:
                              props.formValues[props.selector][row.id].value,
                            onChange: (e) => {
                              let valueToSet =
                                col.id === "baseDirectionVert" ||
                                col.id === "baseDirectionHor"
                                  ? e.currentTarget.value
                                  : e;
                              props.formValues[props.selector][row.id].setter(
                                valueToSet
                              );
                            },
                            hasError:
                              props.formValues[props.selector][row.id].hasError,
                            options: getSimplePrescriptionValuesArray(
                              col.min,
                              col.max,
                              col.res,
                              col.id === "add"
                                ? props.selectPlaceholder
                                : props.selector === "prism"
                                ? prismSelectPlaceholder
                                : null,
                              2,
                              col.values
                            ).map((e, key) => {
                              return (
                                <option key={key} value={e.value}>
                                  {e.name}
                                </option>
                              );
                            }),
                          }}
                        />
                      </div>
                    )}
                    {col.id === "axis" && (
                      <div
                        id={row.id}
                        className={
                          "FullPrescriptionForm__innerContainer__form_select"
                        }
                      >
                        <div
                          className={`FullPrescriptionForm__innerContainer__form_select_prescription${
                            isAxisDisabled(row.id) ? "__disabled" : ""
                          }${props.formErrors[row.id] ? "__error" : ""}`}
                        >
                          {/* <span key={props.formValues[props.selector][row.id].value}> */}
                          <input
                            type="number"
                            min="0"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            id={row.id}
                            name={row.id}
                            title={row.id}
                            value={
                              props.formValues[props.selector][row.id].value ||
                              ""
                            }
                            placeholder={props.formErrors[row.id] ? "-" : ""}
                            disabled={isAxisDisabled(row.id)}
                            onChange={(e) => {
                              props.formValues[props.selector][row.id].setter(
                                parseFloat(e.currentTarget.value)
                              );
                              /* if (
                            e.relatedTarget &&
                            e.relatedTarget["id"] === "submitButtonId"
                          ) {
                            props.onSubmit(e);
                          } */
                            }}
                          />
                          {/* </span> */}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    key={row.id}
                    className={"FullPrescriptionForm__innerContainer__form_row"}
                  >
                    <div
                      id={row.id}
                      className={
                        "FullPrescriptionForm__innerContainer__form_select"
                      }
                    >
                      <div
                        className={`FullPrescriptionForm__innerContainer__form_select_prescription${
                          props.formErrors[row.id] ? "__error" : ""
                        } FullPrescriptionForm__innerContainer__form_select_prescription__add`}
                      >
                        <select
                          tabIndex={0}
                          name={row.id}
                          title={row.id}
                          id={row.id}
                          value={
                            props.formValues[props.selector][row.id].value || ""
                          }
                          onChange={(e) => {
                            let valueToSet =
                              col.id === "baseDirectionVert" ||
                              col.id === "baseDirectionHor"
                                ? e.currentTarget.value
                                : parseFloat(e.currentTarget.value);
                            props.formValues[props.selector][row.id].setter(
                              valueToSet
                            );
                          }}
                        >
                          {getSimplePrescriptionValuesArray(
                            col.min,
                            col.max,
                            col.res,
                            col.id === "add"
                              ? props.selectPlaceholder
                              : props.selector === "prism"
                              ? prismSelectPlaceholder
                              : null,
                            2,
                            col.values
                          ).map((e, key) => {
                            return (
                              <option key={key} value={e.value}>
                                {e.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
        </div>
      )}
      {isMobile && (

        <>
          <div className={"FullPrescriptionForm__innerContainer__form_leftcol"}>
            <div
              className={"FullPrescriptionForm__innerContainer__form_leftlabel"}
            >
              <div>{rightEyeInitials}</div>
              <div>{rightEyeName}</div>
            </div>
            <div
              className={"FullPrescriptionForm__innerContainer__form_leftlabel"}
            >
              <div>{leftEyeInitials}</div>
              <div>{leftEyeName}</div>
            </div>
          </div>
          {props.columns.map((col) => (
            <div
              key={col.id}
              className={
                "FullPrescriptionForm__innerContainer__form_col" +
                (col.id === "add" && props.isAddDisabled()
                  ? " invisible"
                  : "") +
                (col.id === "axis" && disabledAxisLabel(col.rows)
                  ? " disabled--text"
                  : "") +
                (props.isAddDisabled()
                  ? " maxWidthWithoutADD"
                  : " maxWidthIsADD")
              }
            >
              <div className={"FullPrescriptionForm__innerContainer__form_row"}>
                {col.name}
              </div>

              {col.rows.map((row) =>
                props.selector === "prescription" ? (
                  <div
                    key={row.id}
                    className={"FullPrescriptionForm__innerContainer__form_row"}
                  >
                    {col.id === "add" && (
                      <div
                        id={row.id}
                        className={
                          "FullPrescriptionForm__innerContainer__form_select"
                        }
                      >
                        <div
                          className={`FullPrescriptionForm__innerContainer__form_select_prescription${
                            props.formErrors[row.id] ? "__error" : ""
                          } FullPrescriptionForm__innerContainer__form_select_prescription__add`}
                        >
                          <select
                            tabIndex={0}
                            title={row.id}
                            name={row.id}
                            id={row.id}
                            value={
                              props.formValues[props.selector][row.id].value ||
                              ""
                            }
                            onChange={(e) => {
                              let valueToSet =
                                col.id === "baseDirectionVert" ||
                                col.id === "baseDirectionHor"
                                  ? e.currentTarget.value
                                  : parseFloat(e.currentTarget.value);
                              props.formValues[props.selector][row.id].setter(
                                valueToSet
                              );
                            }}
                          >
                            {getSimplePrescriptionValuesArray(
                              col.min,
                              col.max,
                              col.res,
                              col.id === "add"
                                ? props.selectPlaceholder
                                : props.selector === "prism"
                                ? prismSelectPlaceholder
                                : null,
                              2,
                              col.values
                            ).map((e, key) => {
                              return (
                                <option key={key} value={e.value}>
                                  {e.name}
                                </option>
                              );
                            })}
                          </select>
                          {/**TODO Only visible for Costa  */}
                          <div
                            className={
                              "FullPrescriptionForm__innerContainer__form_select__image"
                            }
                          ></div>
                        </div>
                      </div>
                    )}
                    {col.id !== "axis" && col.id !== "add" && (
                      <div
                        id={row.id}
                        className={
                          "FullPrescriptionForm__innerContainer__form_select_custom"
                        }
                      >
                        <BrandComponent
                          componentName={"PrescriptionSelect"}
                          parameter={{
                            name: row.id,
                            id: row.id,
                            value:
                              props.formValues[props.selector][row.id].value,
                            onChange: (e) => {
                              let valueToSet =
                                col.id === "baseDirectionVert" ||
                                col.id === "baseDirectionHor"
                                  ? e.currentTarget.value
                                  : e;
                              props.formValues[props.selector][row.id].setter(
                                valueToSet
                              );
                            },
                            hasError:
                              props.formValues[props.selector][row.id].hasError,
                            options: getSimplePrescriptionValuesArray(
                              col.min,
                              col.max,
                              col.res,
                              col.id === "add"
                                ? props.selectPlaceholder
                                : props.selector === "prism"
                                ? prismSelectPlaceholder
                                : null,
                              2,
                              col.values
                            ).map((e, key) => {
                              return (
                                <option key={key} value={e.value}>
                                  {e.name}
                                </option>
                              );
                            }),
                          }}
                        />
                      </div>
                    )}
                    {col.id === "axis" && (
                      <div
                        id={row.id}
                        className={
                          "FullPrescriptionForm__innerContainer__form_select"
                        }
                      >
                        <div
                          className={`FullPrescriptionForm__innerContainer__form_select_prescription${
                            isAxisDisabled(row.id) ? "__disabled" : ""
                          }${props.formErrors[row.id] ? "__error" : ""}`}
                        >
                          {/* <span key={props.formValues[props.selector][row.id].value}> */}
                          <input
                            type="number"
                            min="0"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            id={row.id}
                            name={row.id}
                            title={row.id}
                            value={
                              props.formValues[props.selector][row.id].value ||
                              ""
                            }
                            placeholder={props.formErrors[row.id] ? "-" : ""}
                            disabled={isAxisDisabled(row.id)}
                            onChange={(e) => {
                              props.formValues[props.selector][row.id].setter(
                                parseFloat(e.currentTarget.value)
                              );
                              /* if (
                          e.relatedTarget &&
                          e.relatedTarget["id"] === "submitButtonId"
                        ) {
                          props.onSubmit(e);
                        } */
                            }}
                          />
                          {/* </span> */}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    key={row.id}
                    className={"FullPrescriptionForm__innerContainer__form_row"}
                  >
                    <div
                      id={row.id}
                      className={
                        "FullPrescriptionForm__innerContainer__form_select"
                      }
                    >
                      <div
                        className={`FullPrescriptionForm__innerContainer__form_select_prescription${
                          props.formErrors[row.id] ? "__error" : ""
                        } FullPrescriptionForm__innerContainer__form_select_prescription__add`}
                      >
                        <select
                          tabIndex={0}
                          name={row.id}
                          title={row.id}
                          id={row.id}
                          value={
                            props.formValues[props.selector][row.id].value || ""
                          }
                          onChange={(e) => {
                            let valueToSet =
                              col.id === "baseDirectionVert" ||
                              col.id === "baseDirectionHor"
                                ? e.currentTarget.value
                                : parseFloat(e.currentTarget.value);
                            props.formValues[props.selector][row.id].setter(
                              valueToSet
                            );
                          }}
                        >
                          {getSimplePrescriptionValuesArray(
                            col.min,
                            col.max,
                            col.res,
                            col.id === "add"
                              ? props.selectPlaceholder
                              : props.selector === "prism"
                              ? prismSelectPlaceholder
                              : null,
                            2,
                            col.values
                          ).map((e, key) => {
                            return (
                              <option key={key} value={e.value}>
                                {e.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export const formTableColumnsHOC = (
  initProps: Omit<FormTableProps, "columns">
) => {
  return (props) => <FormTable {...initProps} columns={props.columns} />;
};

export const PrismForm = (props) => {
  const verticalName = useTranslate(
    "steps.advancedPrescription.manual.vertical"
  );
  const baseDirectionName = useTranslate(
    "steps.advancedPrescription.manual.baseDirection"
  );
  const horizontalName = useTranslate(
    "steps.advancedPrescription.manual.horizontal"
  );
  return (
    <FormTable
      columns={utils.getPrismTableColumns(props.prescriptionDefaults)(
        verticalName,
        horizontalName,
        baseDirectionName
      )}
      selector="prism"
      cylOD={props.cylOD}
      cylOS={props.cylOS}
      isAddDisabled={props.isAddDisabled}
      onSubmit={props.onSubmit}
      formErrors={props.formErrors}
      selectPlaceholder={props.selectPlaceholder}
      formValues={props.formValues}
    />
  );
};
