import React, { useEffect, useState } from "react";
import "./default.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { workflow } from "../../configurator/models/Workflow";
import { BrandComponent } from "../common/BrandComponent";
import { modalsActions } from "../../redux/slices/modals";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { workflowActions } from "../../../src/redux/slices/workflow";

interface MainHeaderProps {}

export function MainHeader({}: MainHeaderProps) {
  const currentStep = useSelector(
    (state: any) => state.workflow.currentStep?.progressive
  );
  const addInsuranceLabel = useTranslate("insuranceButtonLabel");
  const selectedPrescriptionMode = useSelector(
    (state: any) => state.workflow?.selectedPrescriptionMode
  );
  const dispatch = useDispatch();

  const reduxDispatch = useDispatch();

  const getDataElementIdForClose = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-BackToProd";
  };

  const getDataElementIdForBack = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-Back";
  };

  const getDataElementIdForInsurance = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-UseInsurance";
  };

  const getDataDescriptionForBack = () => {
    let landingStep = workflow.getLadindingStepFromBack();
    if (landingStep) {
      return getCorrectAnalyticsStepContainerName(landingStep.key);
    }
  };

  const handleBack = () => {
    if (selectedPrescriptionMode) {
      dispatch(workflowActions.setSelectedPrescriptionMode(false));
    } else {
      workflow.goPrevStep();
    }
  };

  const renderDesktop = () => {
    return (
      <div className={"MainHeader__container"}>
        <div className={"MainHeader__left"}>
          <div
            className={
              "MainHeader__back" +
              (workflow.currentStep?.progressive > 1 ? "" : " invisible")
            }
            data-element-id={getDataElementIdForBack()}
            data-description={getDataDescriptionForBack()}
            onClick={() => handleBack()}
          >
            <svg
              width="9"
              height="16"
              viewBox="0 0 9 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.70711 0.292893C8.31658 -0.0976312 7.68342 -0.0976312 7.29289 0.292893L0.292893 7.29289C-0.0976321 7.68342 -0.0976322 8.31658 0.292893 8.70711L7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071C9.09763 15.3166 9.09763 14.6834 8.70711 14.2929L2.41421 8L8.70711 1.70711C9.09763 1.31658 9.09763 0.683418 8.70711 0.292893Z"
                fill="#222222"
              />
            </svg>
          </div>
        </div>
        <div className={"MainHeader__center"}>
          <BrandComponent componentName="HeaderProgressBar" />
        </div>
        <div className={"MainHeader__right"}>
          <div
            className={"MainHeader__close"}
            data-element-id={getDataElementIdForClose()}
            onClick={() => reduxDispatch(modalsActions.setShowExitModal(true))}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8517 2.07396L15.1169 1.80879L14.8517 1.54363L14.459 1.15085L14.1938 0.885686L13.9286 1.15085L8.00129 7.07818L2.07396 1.15085L1.80879 0.885686L1.54363 1.15085L1.15085 1.54363L0.885686 1.80879L1.15085 2.07396L7.07819 8.00129L1.15085 13.9286L0.885686 14.1938L1.15085 14.459L1.54363 14.8517L1.80879 15.1169L2.07396 14.8517L8.00129 8.9244L13.9286 14.8517L14.1938 15.1169L14.459 14.8517L14.8517 14.459L15.1169 14.1938L14.8517 13.9286L8.9244 8.00129L14.8517 2.07396Z"
                fill="#222222"
                stroke="#222222"
                strokeWidth="0.75"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  };

  const renderMobile = () => {
    const configInsurance = useSelector(
      (state: any) => state.config?.insuranceModule
    );
    const insuranceEnabled = useSelector(
      (state: any) => state.insurance?.insuranceEnabled
    );
    const insuranceLoading = useSelector(
      (state: any) => state.insurance?.loading
    );
    const removeInsuranceFunction = useSelector(
      (state: any) => state.config?.insuranceModule?.removeInsuranceBenefits
    );
    const insuranceLabelRemove = useTranslate("insuranceLabelRemove");
    const insuranceSyncedLabel = useTranslate("insuranceSyncedLabel");
    const [isInsuranceEnabled, setIsInsuranceEnabled] = useState(false);
    const [isInsuranceLoading, setIsInsuranceLoading] = useState(false);

    const isInsurable = () => {
      return !!configInsurance;
    };

    useEffect(() => {
      setIsInsuranceEnabled(insuranceEnabled);
    }, [insuranceEnabled]);

    useEffect(() => {
      setIsInsuranceLoading(insuranceLoading);
    }, [insuranceLoading]);

    const handleInsuranceClick = () => {
      if (configInsurance) {
        if (!isInsuranceEnabled && configInsurance.openInsurancePanel) {
          configInsurance.openInsurancePanel();
        } else if (
          isInsuranceEnabled &&
          configInsurance.removeInsuranceBenefits
        ) {
          configInsurance.removeInsuranceBenefits();
        }
      }
    };

    return (
      <div
        className={
          "MainHeader__containerMobile" +
          (isInsuranceEnabled && removeInsuranceFunction
            ? " insuranceEnabled"
            : "")
        }
      >
        <BrandComponent componentName="HeaderProgressBar" />
        <div
          id={"MainHeader__content"}
          className={
            "MainHeader__content" +
            (isInsuranceEnabled && removeInsuranceFunction
              ? " insuranceEnabled"
              : "")
          }
        >
          <div
            className={
              "MainHeader__left" +
              (isInsuranceEnabled && removeInsuranceFunction
                ? " insuranceEnabled"
                : "")
            }
          >
            <div
              className={
                "MainHeader__left__back" +
                " " +
                (!currentStep || currentStep <= 1
                  ? "MainHeader__left__back_invisible"
                  : null)
              }
              onClick={() => handleBack()}
              data-element-id={getDataElementIdForBack()}
              data-description={getDataDescriptionForBack()}
            >
              <svg
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.70711 0.292893C8.31658 -0.0976312 7.68342 -0.0976312 7.29289 0.292893L0.292893 7.29289C-0.0976321 7.68342 -0.0976322 8.31658 0.292893 8.70711L7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071C9.09763 15.3166 9.09763 14.6834 8.70711 14.2929L2.41421 8L8.70711 1.70711C9.09763 1.31658 9.09763 0.683418 8.70711 0.292893Z"
                  fill="#222222"
                />
              </svg>
            </div>
          </div>
          <div
            id={"MainHeader__center"}
            className={
              "MainHeader__center" +
              (isInsuranceEnabled && removeInsuranceFunction
                ? " insuranceEnabled"
                : "")
            }
          >
            <BrandComponent componentName="HeaderPrice" />
            {isInsurable() && !isInsuranceEnabled && (
              <a
                className="MainHeader__insurance"
                onClick={handleInsuranceClick}
                data-element-id={getDataElementIdForInsurance()}
              >
                {addInsuranceLabel}
              </a>
            )}
            {isInsuranceEnabled && (
              <div
                className={"MainHeader__insurance__insuranceEnabled"}
                data-element-id={getDataElementIdForInsurance()}
              >
                {insuranceSyncedLabel}
                <BrandComponent
                  componentName="InsuranceTooltip"
                  parameter={{ id: "tooltipMobile", displayBelow: true }}
                />
              </div>
            )}
            {isInsuranceEnabled && removeInsuranceFunction && (
              <span
                className="MainHeader__removeInsuranceLabel"
                onClick={handleInsuranceClick}
              >
                {insuranceLabelRemove}
              </span>
            )}
          </div>
          <div
            className={
              "MainHeader__right" +
              (isInsuranceEnabled && removeInsuranceFunction
                ? " insuranceEnabled"
                : "")
            }
            data-element-id={getDataElementIdForClose()}
            onClick={() => reduxDispatch(modalsActions.setShowExitModal(true))}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8517 2.07396L15.1169 1.80879L14.8517 1.54363L14.459 1.15085L14.1938 0.885686L13.9286 1.15085L8.00129 7.07818L2.07396 1.15085L1.80879 0.885686L1.54363 1.15085L1.15085 1.54363L0.885686 1.80879L1.15085 2.07396L7.07819 8.00129L1.15085 13.9286L0.885686 14.1938L1.15085 14.459L1.54363 14.8517L1.80879 15.1169L2.07396 14.8517L8.00129 8.9244L13.9286 14.8517L14.1938 15.1169L14.459 14.8517L14.8517 14.459L15.1169 14.1938L14.8517 13.9286L8.9244 8.00129L14.8517 2.07396Z"
                fill="#222222"
                stroke="#222222"
                strokeWidth="0.75"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderDesktop()}
      {renderMobile()}
    </>
  );
}
