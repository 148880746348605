import React from "react";
import { useTranslation } from "../../configurator/translations/TranslationHooks";

interface TranslateTextOpt {
  lang?: string;
  label: string;
}

/**
 * Component that can be used to translate a label. It simply calls the ```useTranslation()```
 * hook and returns the translated text. In case you need to do some processing on the translated
 * text, it is recommended to call the hook directly.
 */
export function TranslateText({ lang, label }: TranslateTextOpt) {
  const translation = useTranslation();

  return <>{translation(label)}</>;
}
