import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  checkGVP,
  getPriceForGVPStep,
  getPriceFromPackageAndFiltered,
} from "../ConfigLoader";
import { useCurrentPackages } from "./useCurrentPackages";

export function usePriceStepItem() {
  const currentPackages = useCurrentPackages();
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);
  const configFrame = useSelector((state: any) => state.config?.data.frame);
  const [price, setPrice] = useState(
    () => (filters?: Record<string, any>, currentPackageA?) =>
      getPriceFromPackageAndFiltered(
        currentPackageA || currentPackages,
        filters
      )
  );

  useEffect(() => {
    if (currentPackages && configFrame && currentStep) {
      if (checkGVP(currentPackages)) {
        getPrice(true, configFrame);
      } else {
        getPrice();
      }
    }
  }, [currentPackages, configFrame, currentStep]);

  const getPrice = (isGVP?: boolean, frame?: any) => {
    setPrice(() => (filters?: Record<string, any>, currentPackageA?) => {
      return getPriceFromPackageAndFiltered(
        currentPackageA || currentPackages,
        filters,
        isGVP,
        frame
      );
    });
  };

  return price;
}

export const useGPVPrices = () => {
  return (filters, packages) => getPriceForGVPStep(filters, packages);
};
