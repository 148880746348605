import React, {
  FC,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import { getSimplePrescriptionValuesArray } from "../../utils/prescription";
import { BrandComponent } from "../common/BrandComponent";
import { WarningBanner } from "../WarningBanner/component";
import { isMobile } from "react-device-detect";
import { useTargetDigitalOptometry } from "../../configurator/hooks/index";

import "./default.module.scss";

type IShowBothPdOptions = {
  value: boolean;
  ignoreSplitLogic: boolean;
};

type Props = {
  PD1: number | null | undefined;
  PD2: number | null | undefined;
  showBothPdOptions: IShowBothPdOptions;
  showPDWarning: boolean;
  onPD1Change: (v) => void;
  onPD2Change: (v) => void;
  setShowBothPdOptions: Function;
  pdValueNotSelectedError: boolean;
  CustomCheckbox: FC<{ value: IShowBothPdOptions; setter: Function }>;
  brand: string;
};

export const PupillaryDistance = ({
  showBothPdOptions,
  CustomCheckbox,
  pdValueNotSelectedError,
  PD1,
  PD2,
  onPD1Change,
  onPD2Change,
  setShowBothPdOptions,
  showPDWarning,
  brand,
}: Props) => {
  const pupillaryDistanceTitle = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistance"
  );
  const whatIsIt = useTranslate("steps.advancedPrescription.manual.whatIsIt");
  const pupillaryDistanceMisurePDAction = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistanceMisurePDAction"
  );
  const pdSubtitle = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistanceSubtitle"
  );
  const iHaveTwoPd = useTranslate(
    "steps.advancedPrescription.manual.iHaveTwoPd"
  );

  const pupillaryDistanceWarningValue = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistanceWarningValue"
  );
  const pdRight = useTranslate("steps.advancedPrescription.manual.pdRight");
  const pdLeft = useTranslate("steps.advancedPrescription.manual.pdLeft");

  const prescriptionDefaults = useSelector(
    (state: any) => state.config.prescriptionDefaults
  );
  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.params?.[0]
  );
  const enableDigitalOptometry = useSelector(
    (state: any) => state.config.layoutSettings?.enableDigitalOptometry
  );
  const targetDigitalOptometry = useTargetDigitalOptometry();
  const pdValueWarning = useTranslate(
    "steps.advancedPrescription.manual.alerts.pdValueWarning"
  );
  const pdValueWarningMobile = useTranslate(
    "steps.advancedPrescription.manual.alerts.pdValueWarningMobile"
  );
  const pdValueWarning2 = useTranslate(
    "steps.advancedPrescription.manual.alerts.pdValueWarning2"
  );
  const howToRead = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistanceMisurePDAction"
  );
  const reduxDispatch = useDispatch();

  const checkTargetDigitalOptometry = () => {
    if (targetDigitalOptometry) {
      if (
        targetDigitalOptometry.includes(currentStep.value) ||
        targetDigitalOptometry === "[]" ||
        targetDigitalOptometry.length === 0 ||
        !targetDigitalOptometry
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const showPrescriptionModalPD = useCallback(
    (show: boolean) =>
      reduxDispatch(
        modalsActions.setShowPrescriptionModalPD({
          show,
          modal:
            enableDigitalOptometry && checkTargetDigitalOptometry()
              ? enableDigitalOptometry
              : null,
        })
      ),
    []
  );
  const closeDoublePD = () => {
    setShowBothPdOptions({
      ...showBothPdOptions,
      value: !showBothPdOptions.value,
    });
    onPD1Change(!enableDigitalOptometry ? 63 : null);
    onPD2Change(null);
  };
  return (
    (showBothPdOptions.value || !showBothPdOptions.value) && (
      <div className="PupillaryDistance">
        <div className={"PupillaryDistance__titleContainer"}>
          <div className={"PupillaryDistance__titleContainer__title"}>
            {pupillaryDistanceTitle}
          </div>
          {(!enableDigitalOptometry ||
            (enableDigitalOptometry && !checkTargetDigitalOptometry())) && (
              <div
                tabIndex={0}
                className={"PupillaryDistance__titleContainer__link"}
                onClick={() => showPrescriptionModalPD(true)}
              >
                {whatIsIt}
              </div>
            )}
        </div>
        {enableDigitalOptometry && checkTargetDigitalOptometry() && (
          <div
            data-content={howToRead}
            className="PupillaryDistance__subtitleContainer"
            onClick={() => showPrescriptionModalPD(true)}
            dangerouslySetInnerHTML={{ __html: pdSubtitle }}
          />
        )}
        <div
          className={`PupillaryDistance__optionsContainer ${showBothPdOptions.value ? "both_values" : ""
            }`}
        >
          {showBothPdOptions.value && brand === "rayban" && (
            <div className="PupillaryDistance__bothValues">
              <div className="PupillaryDistance__checkboxLabel">{pdRight}</div>
              <div
                className={`PupillaryDistance__optionsContainer__select_container ${showBothPdOptions.value ? "margin" : ""
                  }`}
              >
                <select
                  className={
                    /* formErrors["PD_1"] || */ pdValueNotSelectedError && !PD1
                      ? "error"
                      : showBothPdOptions.value
                        ? "__DoubleSelect"
                        : ""
                  }
                  id="PD_1"
                  name="PD_1"
                  title="PD_1"
                  value={PD1 || ""}
                  onChange={(e) => {
                    onPD1Change(e.target.value);
                  }}
                >
                  {enableDigitalOptometry && (
                    <option /* disabled={!!PD1} */ value={undefined}>-</option>
                  )}
                  {(!showBothPdOptions.value
                    ? getSimplePrescriptionValuesArray(
                      prescriptionDefaults.pdMin,
                      prescriptionDefaults.pdMax,
                      prescriptionDefaults.pdRes
                    )
                    : getSimplePrescriptionValuesArray(
                      Math.floor(prescriptionDefaults.pdMin / 2),
                      Math.floor(prescriptionDefaults.pdMax / 2),
                      prescriptionDefaults.pdRes
                    )
                  ).map((e, key) => {
                    return (
                      <Fragment key={key}>
                        <option key={key} value={e.value}>
                          {e.name}
                        </option>

                        {enableDigitalOptometry && (
                          <option
                            key={key + 10}
                            value={(e.value as number) + 0.5}
                          >
                            {(e.name as number) + 0.5}
                          </option>
                        )}
                      </Fragment>
                    );
                  })}
                </select>
              </div>
            </div>
          )}
          {showBothPdOptions.value && brand !== "rayban" && (
            <>
              <div className="PupillaryDistance__checkboxLabel">{pdRight}</div>
              <div
                className={`PupillaryDistance__optionsContainer__select_container ${showBothPdOptions.value ? "margin" : ""
                  }`}
              >
                <select
                  className={
                    /* formErrors["PD_1"] || */ pdValueNotSelectedError && !PD1
                      ? "error"
                      : showBothPdOptions.value
                        ? "__DoubleSelect"
                        : ""
                  }
                  id="PD_1"
                  name="PD_1"
                  title="PD_1"
                  value={PD1 || ""}
                  onChange={(e) => {
                    onPD1Change(e.target.value);
                  }}
                >
                  {(enableDigitalOptometry || brand === "opsm") && (
                    <option /* disabled={!!PD1} */ value={undefined}>-</option>
                  )}
                  {(!showBothPdOptions.value
                    ? getSimplePrescriptionValuesArray(
                      prescriptionDefaults.pdMin,
                      prescriptionDefaults.pdMax,
                      prescriptionDefaults.pdRes
                    )
                    : getSimplePrescriptionValuesArray(
                      Math.floor(prescriptionDefaults.pdMin / 2),
                      Math.floor(prescriptionDefaults.pdMax / 2),
                      prescriptionDefaults.pdRes
                    )
                  ).map((e, key) => {
                    return (
                      <Fragment key={key}>
                        <option
                          key={key}
                          value={e.value}
                          selected={e.value === PD1}
                        >
                          {e.name + (brand === "opsm" ? ".0" : "")}
                        </option>

                        {brand !== "clearly" ? (
                          enableDigitalOptometry && (
                            <option
                              key={key + 10}
                              value={(e.value as number) + 0.5}
                            >
                              {(e.name as number) + 0.5}
                            </option>
                          )
                        ) : (
                          <option
                            key={key + 10}
                            value={(e.value as number) + 0.5}
                          >
                            {(e.name as number) + 0.5}
                          </option>
                        )}
                      </Fragment>
                    );
                  })}
                </select>
              </div>
            </>
          )}

          {showBothPdOptions.value &&
            (brand === "rayban" ? (
              <div className="PupillaryDistance__bothValues">
                <div className="PupillaryDistance__checkboxLabel">{pdLeft}</div>
                <div
                  className={`PupillaryDistance__optionsContainer__select_container ${showBothPdOptions.value ? "margin" : ""
                    }`}
                >
                  <select
                    className={`${showBothPdOptions.value ? "__DoubleSelect" : ""
                      }${pdValueNotSelectedError && !PD2 ? " error" : ""}`}
                    id="PD_2"
                    name="PD_2"
                    title="PD_2"
                    value={PD2 || ""}
                    onChange={(e) => onPD2Change(parseFloat(e.target.value))}
                  >
                    {enableDigitalOptometry && (
                      <option value={undefined}>-</option>
                    )}
                    {getSimplePrescriptionValuesArray(
                      Math.floor(prescriptionDefaults.pdMin / 2),
                      Math.floor(prescriptionDefaults.pdMax / 2),
                      prescriptionDefaults.pdRes
                    ).map((e, key) => {
                      return (
                        <Fragment key={key}>
                          <option key={key} value={e.value}>
                            {e.name}
                          </option>
                          {enableDigitalOptometry && (
                            <option
                              key={key + 10}
                              value={(e.value as number) + 0.5}
                            >
                              {(e.name as number) + 0.5}
                            </option>
                          )}
                        </Fragment>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : (
              <>
                <div className="PupillaryDistance__checkboxLabel">{pdLeft}</div>
                <div
                  className={`PupillaryDistance__optionsContainer__select_container ${showBothPdOptions.value ? "margin" : ""
                    }`}
                >
                  <select
                    className={`${showBothPdOptions.value ? "__DoubleSelect" : ""
                      }${pdValueNotSelectedError && !PD2 ? " error" : ""}`}
                    id="PD_2"
                    name="PD_2"
                    title="PD_2"
                    value={PD2 || ""}
                    onChange={(e) => onPD2Change(parseFloat(e.target.value))}
                  >
                    {(enableDigitalOptometry || brand === "opsm") && (
                      <option value={undefined}>-</option>
                    )}
                    {getSimplePrescriptionValuesArray(
                      Math.floor(prescriptionDefaults.pdMin / 2),
                      Math.floor(prescriptionDefaults.pdMax / 2),
                      prescriptionDefaults.pdRes
                    ).map((e, key) => {
                      return (
                        <Fragment key={key}>
                          <option
                            key={key}
                            value={e.value}
                            selected={e.value === PD2}
                          >
                            {e.name + (brand === "opsm" ? ".0" : "")}
                          </option>

                          {brand !== "clearly" ? (
                            enableDigitalOptometry && (
                              <option
                                key={key + 10}
                                value={(e.value as number) + 0.5}
                              >
                                {(e.name as number) + 0.5}
                              </option>
                            )) : (
                            <option
                              key={key + 10}
                              value={(e.value as number) + 0.5}
                            >
                              {(e.name as number) + 0.5}
                            </option>
                          )}
                        </Fragment>
                      );
                    })}
                  </select>
                </div>
              </>
            ))}
          {!showBothPdOptions.value && (
            <>
              <div
                className={`PupillaryDistance__optionsContainer__select_container ${showBothPdOptions.value ? "margin" : ""
                  }`}
              >
                <select
                  className={`${showBothPdOptions.value ? "__DoubleSelect" : ""
                    }${pdValueNotSelectedError && !PD1 ? " error" : ""}`}
                  id="PD_1"
                  name="PD_1"
                  title="PD_1"
                  value={PD1 || ""}
                  onChange={(e) => onPD1Change(parseFloat(e.target.value))}
                >
                  {(enableDigitalOptometry || brand === "opsm") && (
                    <option value={undefined}>-</option>
                  )}
                  {getSimplePrescriptionValuesArray(
                    Math.floor(prescriptionDefaults.pdMin),
                    Math.floor(prescriptionDefaults.pdMax),
                    prescriptionDefaults.pdRes
                  ).map((e, key) => {
                    return (
                      <Fragment key={key}>
                        <option
                          key={key}
                          value={e.value}
                          selected={e.value === PD1}
                        >
                          {e.name + (brand === "opsm" ? ".0" : "")}
                        </option>

                        {brand !== "clearly" ? (
                          enableDigitalOptometry && (
                            <option
                              key={key + 10}
                              value={(e.value as number) + 0.5}
                            >
                              {(e.name as number) + 0.5}
                            </option>
                          )
                        ) : (
                          <option
                            key={key + 10}
                            value={(e.value as number) + 0.5}
                          >
                            {(e.name as number) + 0.5}
                          </option>
                        )}
                      </Fragment>
                    );
                  })}
                </select>
              </div>
              <div className="PupillaryDistance__checkboxContainer">
                <CustomCheckbox
                  value={showBothPdOptions}
                  setter={setShowBothPdOptions}
                />

                <div className="PupillaryDistance__checkBoxText">
                  {iHaveTwoPd}
                </div>
              </div>
            </>
          )}
        </div>
        {showBothPdOptions.value && (
          <div
            className="PupillaryDistance__checkboxContainer"
            style={{ margin: "24px 0px 0px" }}
          >
            <CustomCheckbox value={showBothPdOptions} setter={closeDoublePD} />

            <div className="PupillaryDistance__checkBoxText">{iHaveTwoPd}</div>
          </div>
        )}
        {showPDWarning && (
          <BrandComponent
            componentName="WarningBanner"
            parameter={{
              textMobile: brand === "opsm" ? pdValueWarningMobile : "",
              text: enableDigitalOptometry ? pdValueWarning2 : pdValueWarning,
              dataContent: pupillaryDistanceMisurePDAction,
              onClick: () =>
                enableDigitalOptometry &&
                reduxDispatch(
                  modalsActions.setShowPrescriptionModalPD({
                    show: true,
                    modal: enableDigitalOptometry,
                  })
                ),
            }}
          />
        )}
        {pdValueNotSelectedError && (
          <div
            className="PupillaryDistance__pdValueNotSelectedWarning"
            data-content={pupillaryDistanceMisurePDAction}
            onClick={() => showPrescriptionModalPD(true)}
          >
            {pupillaryDistanceWarningValue}
          </div>
        )}
      </div>
    )
  );
};
