import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";
import { isMobile } from "react-device-detect";
import { useCurrentType } from "./useCurrentType";

export function useLargeIcons() {
  const largeIconsCookie = getCookieByName("enableLargeIcons", true);
  const largeIconsConfig = useSelector(
    (state: any) => state.config?.layoutSettings?.enableLargeIcons
  );

  const content = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  const currentType = useCurrentType();

  const isLargeIconsActive = useMemo(() => {
    if (![null, undefined].includes(largeIconsCookie)) {
      return largeIconsCookie as boolean;
    }
    if (![null, undefined].includes(largeIconsConfig)) {
      return largeIconsConfig as boolean;
    }
    return false;
  }, [largeIconsCookie, largeIconsConfig]);

  const getIcons = useCallback(
    (id) => {
      const allContents = Object.entries<{ [prop: string]: any }>(
        content
      ).reduce((acc, [step, props]) => {
        return { ...acc, ...props };
      }, {});

      //Clear case
      if (id.includes("clear"))
        return [
          "rxc-icon-treatments-clear.png",
          "rxc-icon-3d-treatments-clear.apng",
        ];

      //Proceed-as-is case
      if (id.includes("antiReflective$null")) {
        if (currentType === "EYEGLASSES") {
          return [
            "rxc-icon-finishing-proceed_as_is.png",
            "rxc-icon-3d-finishing-proceed_as_is.apng",
          ];
        } else {
          return [
            "rxc-icon-finishing-scratch_resistant_sun",
            "rxc-icon-3d-finishing-scratch_resistant_sun.apng",
          ];
        }
      }

      const info =
        id.indexOf("$") >= 0 ? allContents[id.split("$")[1]] : allContents[id];
      if (!info) return null; //TODO add log
      const images = [];

      if (info.imageFileName1 && info.imageFileName2) {
        // if (isMobile) return [`${info.imageFileName2}.apng`];
        // else {
        return [`${info.imageFileName1}.png`, `${info.imageFileName2}.apng`];
        //}
      } else if (info.imageFileName) {
        return [`${info.imageFileName}.apng`];
      }
      return images;
    },
    [content, currentType]
  );

  return {
    isLargeIconsActive,
    getIcons,
  };
}
