import { useCurrentType } from "./useCurrentType";
import { getCorrectKey } from "../ConfigLoader";
import { useSelector } from "react-redux";

export function useRetriveContextualMessage() {
  const currentType = useCurrentType();
  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.key
  );
  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const configData = useSelector((state: any) => state.config?.data);
  let ctxKey: string = getCorrectKey(
    currentStep,
    currentType === "EYEGLASSES" ? true : false,
    configPackages,
    configData
  );
  const ctxMessages = useSelector((state: any) => state?.config?.ctxMessages);
  let ctxMessage = "";
  if (ctxMessages.hasOwnProperty(ctxKey)) {
    ctxMessage = ctxMessages[ctxKey];
  }
  return ctxMessage;
}
