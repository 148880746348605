import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../../../../docFiles";
import ReferencedSection from "../../../components/common/ReferencedSection";
import { workflowActions } from "../../../redux/slices/workflow";
import { getStepBadgeType } from "../../ConfigLoader";
import {
  useAllCurrentPackages,
  useConfigContent,
  useContextualMessage,
  useMoreInfo,
  usePriceStepItem,
  useGetDiscount,
  useEnableBrandLastStep,
} from "@hooks";
import { workflow } from "../../models/Workflow";
import { useTranslation } from "../../translations/TranslationHooks";
import { StepComponentOptions } from "../StepComponent";

export function Brand(props: StepComponentOptions) {
  const translate = useTranslation();
  const currentPackages = useAllCurrentPackages();
  const mapperMoreInfo = useMoreInfo("brand");
  const brandLastStep = useEnableBrandLastStep();
  const ctxMessage = useSelector(
    (state: any) => state?.config?.ctxMessages?.brand
  );

  const dispatch = useDispatch();
  const { getDiscount, isLoading } = useGetDiscount();

  const onBrandSelected = (id: string, skipped: boolean) => {
    const priceObj = getPriceStep({ brand: id });
    let addedParams = [];
    if (skipped) {
      addedParams = workflow.currentStep.params;
    }
    dispatch(workflowActions.setPromoType(null));
    if (!brandLastStep) {
      workflow.goToStep(
        "LensColor",
        [
          ...addedParams,
          {
            key: "brand",
            value: id,
            price: priceObj.price,
            discount: priceObj.discount,
            insPrice: skipped && priceObj.price == 0 ? 0 : priceObj.insPrice,
          },
        ],
        skipped
      );
    } else {
      workflow.goNextStep(
        [
          ...addedParams,
          {
            key: "brand",
            value: id,
            price: priceObj.price,
            discount: priceObj.discount,
            insPrice: skipped && priceObj.price == 0 ? 0 : priceObj.insPrice,
          },
        ],
        skipped
      );
    }
  };

  const configBrand: any[] = useConfigContent("Brand");
  const getPriceStep = usePriceStepItem();

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    if (currentPackages && configBrand && mapperMoreInfo) {
      let brandsT = configBrand.filter((c) =>
        currentPackages.validRXPackages.some(
          (cp) => cp.lensPackage.brand === c.id
        )
      );
      let brandsGreyOut = configBrand
        .filter((c) =>
          currentPackages.invalidRXPackages.some(
            (cp) => cp.lensPackage.brand === c.id
          )
        )
        .map((brand) => {
          return {
            ...brand,
            disabled: true,
          };
        })
        .filter((brand) => !brandsT.find((b) => b.id === brand.id));

      brandsT = mapperMoreInfo(brandsT).concat(brandsGreyOut);
      setBrands(brandsT);
    }
  }, [configBrand, currentPackages, mapperMoreInfo]);

  useEffect(() => {
    for (const brand of brands) {
      const { id } = brand;
      getDiscount(id, currentPackages.validRXPackages, { brand: id });
    }
  }, [brands]);

  const getKey = (title: string) => "Brand_sc_" + title.split(" ").join("_");

  const getParameter = (c) => {
    if (currentPackages) {
      const priceObj = c.disabled ? null : getPriceStep({ brand: c.id });
      return {
        ...c,
        textBadge: c.badge,
        imageUrl: c.imageFileName,
        price: priceObj?.price,
        discount: priceObj?.discount,
        insPrice: priceObj?.insPrice,
        badge: c.badge ? getStepBadgeType(c.badge) : null,
        disabled: c.disabled || c.badge == "coming soon",
        onClick:
          c.badge == "coming soon" || c.disabled
            ? null
            : () => onBrandSelected(c.id, false),
        discountLoading: isLoading(c.id),
      };
    }
  };

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: translate("steps.brand.title"),
        subtitle: ctxMessage,
        showGrayOutBanner: brands && brands.find((b) => b.disabled),
      }}
    >
      <div className={"Brand__container"}>
        <BrandComponent
          componentName="SkipContainer"
          parameter={{
            stepName: "Brand",
            onNext: (selected: any, skipped?: boolean) => {
              onBrandSelected(selected?.id, skipped);
            },
            parameterPreselection: ["brand"],
            onPreselect: (selected, skipped: boolean) => {
              onBrandSelected(selected.brand, skipped);
            },
          }}
        >
          {brands &&
            brands.map((c) => (
              <BrandComponent
                key={getKey(c.title)}
                componentName="StepCard"
                parameter={getParameter(c)}
              />
            ))}
        </BrandComponent>
      </div>
      <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
    </BrandComponent>
  );
}
