import React, { useEffect } from "react";
import "./default.module.scss";
import GenericButton from "../common/GenericButton";
import { useDispatch } from "react-redux";
import { workflowActions } from "../../redux/slices/workflow";
import { useTranslate } from "../../configurator/translations/TranslationHooks";

export const ErrorModal = () => {
  const reduxDispatch = useDispatch();

  useEffect(() => {
    reduxDispatch(workflowActions.setLoadingDynamicDiscounts(false));
    reduxDispatch(workflowActions.setLoadingStep(false));
  }, []);

  const title = useTranslate("error.title");
  const description = useTranslate("error.description");
  const buttonLabel = useTranslate("error.buttonLabel");
  return (
    <div className="ErrorModal__container">
      <div className="ErrorModal__overlay" />
      <div className="ErrorModal__container__content">
        <h2 className="ErrorModal__container__content__title">{title}</h2>
        <p
          className="ErrorModal__container__content__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <GenericButton
          className="ErrorModal__container__content__button"
          handleClick={() => window.RXC.rxcWidget.close("#rxcApp")}
        >
          {buttonLabel}
        </GenericButton>
      </div>
    </div>
  );
};
