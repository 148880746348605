export default {
  getPrismTableColumns: (prescriptionDefaults) => {
    return (verticalName, horizontalName, baseDirectionName) => [
      {
        id: "vertical",
        type: "vertical",
        placeholder: "-",
        required: true,
        name: verticalName,
        min: prescriptionDefaults.prismVertMin,
        max: prescriptionDefaults.prismVertMax,
        res: prescriptionDefaults.prismVertRes,
        rows: [
          {
            id: "right_eye_vertical",
          },
          {
            id: "left_eye_vertical",
          },
        ],
      },
      {
        id: "baseDirectionVert",
        type: "baseDirectionVert",
        placeholder: "-",
        required: true,
        name: baseDirectionName,
        values: prescriptionDefaults.prismBaseVert,
        rows: [
          {
            id: "right_eye_baseDirVert",
          },
          {
            id: "left_eye_baseDirVert",
          },
        ],
      },
      {
        id: "horizontal",
        type: "horizontal",
        placeholder: "-",
        required: true,
        name: horizontalName,
        min: prescriptionDefaults.prismHorMin,
        max: prescriptionDefaults.prismHorMax,
        res: prescriptionDefaults.prismHorRes,
        rows: [
          {
            id: "right_eye_horizontal",
          },
          {
            id: "left_eye_horizontal",
          },
        ],
      },
      {
        id: "baseDirectionHor",
        type: "baseDirectionHor",
        placeholder: "-",
        required: true,
        name: baseDirectionName,
        values: prescriptionDefaults.prismBaseHor,
        rows: [
          {
            id: "right_eye_baseDirHor",
          },
          {
            id: "left_eye_baseDirHor",
          },
        ],
      },
    ];
  },
};
