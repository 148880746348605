import React, { useEffect, useState } from "react";
import "./default.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { modalsActions } from "../../redux/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import HtmlParser from "html-react-parser";
import { BrandComponent } from "../common/BrandComponent";
import { Prescription } from "../../types";

export function PrescriptionReviewSeeDetails(props) {
  const reduxDispatch = useDispatch();
  const currentPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );
  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const filePreviewLabel = useTranslate(
    "steps.advancedPrescription.upload.filePreviewTitle"
  );
  const doctorPreviewLabel = useTranslate(
    "steps.advancedPrescription.doctor.doctorPreviewTitle"
  );
  const manualPreviewLabel = useTranslate(
    "steps.advancedPrescription.manual.manualPreviewTitle"
  );
  const [prescriptionFlow, setPrescriptionFlow] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [filePath, setFilePath] = useState(null);

  const [uploadFromNested, setUploadFromNested] = useState(false);
  const [callMyDocFromNested, setCMDfromNested] = useState(false);

  useEffect(() => {
    if (currentPrescription && prescriptionModule) {
      setPrescriptionFlow(currentPrescription.prescriptionFlow);
      let upFromNested =
        currentPrescription?.savedFileName &&
        currentPrescription.prescriptionFlow === "NESTED_CA";
      let cmdFromNested =
        (currentPrescription as any)?.name &&
        (currentPrescription as any)?.phone &&
        currentPrescription.prescriptionFlow === "NESTED_CA";
      setUploadFromNested(upFromNested);
      setCMDfromNested(cmdFromNested);
      if (currentPrescription.prescriptionFlow === "UPLOAD" || upFromNested) {
        setLoadingFile(true);
        if (prescriptionModule.downloadExtendedPrescription) {
          prescriptionModule
            .downloadExtendedPrescription({
              savedFileName: currentPrescription.savedFileName,
            })
            .then((res) => {
              setFilePath(res.fileData);
              setLoadingFile(false);
            })
            .catch((err) => {
              setFilePath("");
              setLoadingFile(false);
            });
        } else {
          console.error("Missing downloadExtendedPrescriptionFunction");
          setFilePath("");
          setLoadingFile(false);
        }
      }
    }
  }, [currentPrescription, prescriptionModule]);

  const getDoctorInfo = (prescription: any) => {
    return {
      clinic: prescription.clinic,
      address: prescription.address,
      phone: prescription.phone,
      name: prescription.name,
      fax: prescription.fax,
    };
  };

  return (
    prescriptionFlow && (
      <div className="PrescriptionReviewSeeDetails">
        <div
          className={"PrescriptionReviewSeeDetails__container__overlay"}
        ></div>
        <div
          className={
            "PrescriptionReviewSeeDetails__container__popup" +
            (prescriptionFlow === "CMD" || callMyDocFromNested
              ? " widthUnset"
              : "") +
            (loadingFile ? " loading" : "")
          }
        >
          <a
            className={"PrescriptionReviewSeeDetails__container__exit"}
            onClick={() =>
              reduxDispatch(
                modalsActions.setShowPrescriptionReviewSeeDetails(false)
              )
            }
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8517 2.07396L15.1169 1.80879L14.8517 1.54363L14.459 1.15085L14.1938 0.885686L13.9286 1.15085L8.00129 7.07818L2.07396 1.15085L1.80879 0.885686L1.54363 1.15085L1.15085 1.54363L0.885686 1.80879L1.15085 2.07396L7.07819 8.00129L1.15085 13.9286L0.885686 14.1938L1.15085 14.459L1.54363 14.8517L1.80879 15.1169L2.07396 14.8517L8.00129 8.9244L13.9286 14.8517L14.1938 15.1169L14.459 14.8517L14.8517 14.459L15.1169 14.1938L14.8517 13.9286L8.9244 8.00129L14.8517 2.07396Z"
                fill="#222222"
                stroke="#222222"
                strokeWidth="0.75"
              />
            </svg>
          </a>
          <div className={"PrescriptionReviewSeeDetails__container__title"}>
            {(prescriptionFlow === "UPLOAD" || uploadFromNested) &&
              filePreviewLabel}
            {(prescriptionFlow === "CMD" || callMyDocFromNested) &&
              doctorPreviewLabel}
            {prescriptionFlow === "MANUAL" && manualPreviewLabel}
          </div>
          <div
            className={
              "PrescriptionReviewSeeDetails__container__previewContainer"
            }
          >
            {(prescriptionFlow === "UPLOAD" || uploadFromNested) &&
              !loadingFile && <img src={filePath} alt="Document" />}
            {(prescriptionFlow === "UPLOAD" || uploadFromNested) &&
              loadingFile && (
                <BrandComponent
                  componentName="Loader"
                  parameter={{
                    greyLoader: true,
                    style: { width: 40, height: 40 },
                  }}
                />
              )}
            {(prescriptionFlow === "CMD" || callMyDocFromNested) && (
              <DoctorTable selected={getDoctorInfo(currentPrescription)} />
            )}
            {prescriptionFlow === "MANUAL" && currentPrescription && (
              <ManualTable {...currentPrescription} />
            )}
          </div>
        </div>
      </div>
    )
  );
}

const DoctorTable = (props) => {
  const doctorNameTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.doctorName"
  );
  const clinicNameTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.clinicName"
  );
  const addressTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.address"
  );
  const phoneTableHeaderLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.phoneNumber"
  );
  const phoneTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.phone"
  );
  const faxTableLabel = useTranslate(
    "steps.advancedPrescription.doctor.table.fax"
  );

  const getTableProps = () => {
    return { rules: "cols" };
  };

  return (
    props && (
      <div className="SearchResult">
        <table {...getTableProps()}>
          <thead>
            <tr>
              <th scope="col">{doctorNameTableLabel}</th>
              {props?.selected?.clinic && (
                <th scope="col">{clinicNameTableLabel}</th>
              )}
              {props?.selected?.address && (
                <th scope="col">{addressTableLabel}</th>
              )}
              {props?.selected?.phone &&
                !props?.selected?.address &&
                !props?.selected?.clinic && (
                  <th scope="col">{phoneTableHeaderLabel}</th>
                )}
            </tr>
          </thead>
          <tbody>
            <td key={props?.selected?.name} data-label="Doctor name">
              {props.selected.name}
            </td>
            {props?.selected?.clinic && (
              <td key={props.selected.clinic} data-label="Clinic Name">
                {props.selected.clinic}
              </td>
            )}
            {props?.selected?.address && (
              <td key={props.selected.address} data-label="Address">
                {" "}
                {HtmlParser(
                  props.selected.address +
                    "<br>" +
                    phoneTableLabel +
                    ": " +
                    props.selected.phone +
                    "<br>" +
                    faxTableLabel +
                    ": " +
                    props.selected.fax
                )}
              </td>
            )}
            {props?.selected?.phone &&
              !props?.selected?.address &&
              !props?.selected?.clinic && (
                <td key={props.selected.phone} data-label="phoneNumber">
                  {" "}
                  {props.selected.phone}
                </td>
              )}
          </tbody>
        </table>
      </div>
    )
  );
};

const ManualTable = ({
  SPH,
  CYL,
  AX,
  ADD,
  PD,
  PRISM_ENABLED,
  VPRISM,
  VBASEDIR,
  HPRISM,
  HBASEDIR,
}: Prescription) => {
  const sphereName = useTranslate(
    "steps.advancedPrescription.manual.seeDetails.sphere"
  );
  const cylinderName = useTranslate(
    "steps.advancedPrescription.manual.seeDetails.cylinder"
  );
  const axisName = useTranslate(
    "steps.advancedPrescription.manual.seeDetails.axis"
  );
  const addName = useTranslate(
    "steps.advancedPrescription.manual.seeDetails.add"
  );
  const pdName = useTranslate(
    "steps.advancedPrescription.manual.seeDetails.pd"
  );
  const odName = useTranslate(
    "steps.advancedPrescription.manual.seeDetails.od"
  );
  const osName = useTranslate(
    "steps.advancedPrescription.manual.seeDetails.os"
  );
  const verticalName = useTranslate(
    "steps.advancedPrescription.manual.seeDetails.vertical"
  );
  const baseDirectionName = useTranslate(
    "steps.advancedPrescription.manual.seeDetails.baseDirection"
  );
  const horizontalName = useTranslate(
    "steps.advancedPrescription.manual.seeDetails.horizontal"
  );

  const lensFeatures = [
    { header: sphereName, value: SPH },
    { header: cylinderName, value: CYL },
    { header: axisName, value: AX },
    { header: addName, value: ADD },
    { header: pdName, value: PD },
  ];
  const lensDirections = [odName, osName];

  const prismFeatures = [
    { header: verticalName, value: VPRISM },
    { header: baseDirectionName, value: VBASEDIR },
    { header: horizontalName, value: HPRISM },
    { header: baseDirectionName, value: HBASEDIR },
  ];

  return (
    <div className="ManualTable">
      <table className="desktop_table">
        <thead>
          <tr>
            <td></td>
            {lensFeatures.map((feature) => (
              <th scope="col">{feature.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">{odName}</th>
            {lensFeatures.map((feature) => {
              let isOnePDValue = false;
              let isOsEqualToOd = false;
              if (feature.header === "PD") {
                isOnePDValue = feature.value?.OS === null;
                // isOsEqualToOd = feature.value?.OS === feature.value?.OD ? true : isOnePDValue
              }
              return (
                <td
                  rowSpan={isOnePDValue ? 2 : 1}
                  className={isOnePDValue ? "centerAlign" : ""}
                >
                  {feature.value?.OD === 0 ? 0 : feature.value?.OD || "-"}
                </td>
              );
            })}
          </tr>
          <tr>
            <th scope="row">{osName}</th>
            {lensFeatures.map((feature) => {
              let isOsEqualToOd = false;
              if (feature.header === "PD") {
                isOsEqualToOd =
                  feature.value?.OS === feature.value?.OD ? true : false;
                if (feature.value?.OS === null) {
                  return null;
                }
              }
              return (
                <td>
                  {feature.value?.OS === 0 ? 0 : feature.value?.OS || "-"}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
      <table className="mobile_table">
        <thead>
          <tr>
            <td></td>
            {lensDirections.map((direction) => (
              <th scope="col">{direction}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {lensFeatures.map((feature) => (
            <tr>
              <th>{feature.header}</th>
              {lensDirections.map((direction) =>
                feature?.value ? (
                  (feature.header !== "PD" || PD.OS) && (
                    <td>
                      {feature.value[direction] === 0
                        ? 0
                        : feature.value[direction] || "-"}
                    </td>
                  )
                ) : (
                  <td>-</td>
                )
              )}
              {feature?.value && feature.header === "PD" && !PD.OS && (
                <td colSpan={2}>
                  {feature.value.OD === 0 ? 0 : feature.value.OD || "-"}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {PRISM_ENABLED && (
        <>
          <table className="desktop_table marginTop">
            <thead>
              <tr>
                <td></td>
                {prismFeatures.map((prismFeature) => (
                  <th scope="col">{prismFeature.header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {lensDirections.map((direction) => (
                <tr>
                  <th scope="row">{direction}</th>
                  {prismFeatures.map((prismFeature) => (
                    <td>{prismFeature.value[direction] || "-"}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <table className="mobile_table marginTop">
            <thead>
              <tr>
                <td></td>
                {lensDirections.map((direction) => (
                  <th scope="col">{direction}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {prismFeatures.map((prismFeature) => (
                <tr>
                  <th scope="row">{prismFeature.header}</th>
                  {lensDirections.map((direction) => (
                    <td>{prismFeature.value[direction] || "-"}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
