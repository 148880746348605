//@ts-ignore
export default {
  opsm: {
    en_US: {
      steps: {
        tilesTitlePrefixProceed: "Proceed with",
        tilesTitlePrefixUpgrade: "Upgrade to",
        advancedPrescription: {
          title: "Do you have a prescription?",
          manual: {
            addPrismValues: "Add Prism values",
            pupillaryDistanceSubtitle: "You can select the default settings of <b>61 for women</b> and <b>64 for men</b> if you have an average or low prescription. If you have a strong prescription (or if you want to know your exact PD), please ",
            whatIsIt: "What is it?",
            commentsTitle: "Comments",
            alerts: {
              axisRangeError: "The AXIS values are incorrect. Please insert a value between 0 and 180.",
              axisZeroError: "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
              sphPositiveNegativeWarning: "You’ve inserted 1 positive and 1 negative value, which is rare. We ask that you would please double-check your prescription. If the value is correct, please proceed.",
              pdValueWarning: "The selected PD is smaller than average, we suggest double checking your prescription. <br>If we need any more information about your prescription, one of our experts will be in touch.",
              pdValueWarningMobile: "The selected PD is smaller than average, we suggest double checking your prescription. If we need any more information about your prescription, one of our experts will be in touch.",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionBody: "but don’t worry, compatible frames are available",
              agreementText: "Yes, my prescription is current, valid and matches the original copy.",
              agreementTextMobile: "Yes, my prescription is current, valid and <br>matches the original copy."
            },
            continueModal: {
              title: "SEND PRESCRIPTION LATER",
              titleNoAdd: "Are you sure you want to continue?",
              message: "By clicking on continue, you will be skipping the prescription step. \n              We’ll ask you again during checkout, and after your order confirmation, you can either upload it or select it from my account.",
              messageNoAdd: "Please make sure to enter the Addition (ADD) value if it is listed in your prescription, otherwise please proceed without.",
              continue: "Yes, Continue",
              continueNoAdd: "Continue without (add)",
              cancel: "No, Go Back"
            },
            issueDate: {
              missingError: "Please insert date",
              formatError: "The date entered is not valid",
              tooltipText: "Before submitting your prescription, please check the expiration date to make sure it is current and valid. We only accept prescriptions that have been issued within the last 2 years. By selecting the tick box and continuing below, you agree to our privacy policy and T&Cs."
            }
          },
          account: {
            card: {
              description: "Log in and select your prescription."
            }
          },
          upload: {
            card: {
              description: "And we'll take care of the rest. We accept the following file formats: ###FILE_FORMATS### (max ###FILE_SIZE### MB) and IWork pages"
            },
            prescriptionUploaded: "Prescription Uploaded",
            pdForm: {
              pdValueWarning: "The selected PD is smaller than average, we suggest double checking your prescription.\nIf we need any more information about your prescription, one of our experts will be in touch."
            },
            filePreviewTitle: "Your prescription"
          },
          doctor: {
            pdForm: {
              pdValueWarning: "The selected PD is smaller than average, we suggest double checking your prescription.\nIf we need any more information about your prescription, one of our experts will be in touch."
            }
          },
          later: {
            card: {
              description: "We'll ask you again during checkout, and after your order confirmation, you can either upload it or select it from my account"
            }
          },
          californian: {
            toggle: "Are you a California resident?",
            info: "Please note that besides adding prescription values manually, California residents are also required to electronically transmit their valid prescription. Choose an option to proceed.",
            chooseTitle: "CHOOSE HOW TO SEND YOUR PRESCRIPTION",
            uploadTitle: "Upload",
            uploadDesc: "We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10 MB) and IWork pages.",
            uploadButton: "Browse",
            callMyDoc: "Call my doctor",
            callMyDocButton: "FIND YOUR DOCTOR",
            yes: "YES",
            no: "NO",
            modalMissingPrescription: {
              title: "Missing prescription values",
              subtitle: "If you don’t fill the values we won’t be able to suggest the right lens for you",
              bottonYes: "YES, CONTINUE",
              bottonNo: "NO, GO BACK"
            }
          }
        },
        type: {
          moreInfo: "More info"
        },
        treatmentsFamily: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included."
        },
        treatments: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included."
        },
        color: {
          polarized: "Polarised only",
          tooltip: {
            polarized: "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            blueLight: "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue"
          }
        },
        addOns: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included."
        },
        review: {
          subtitle: "Your glasses will come with best in class Ray-Ban technology.",
          paymentInstallments: {
            installmentLabel: "Or ###PAYMENT_TYPE### interest-free payments of ###MONTHLY_PRICE###",
            installmentsLabel: "Pay over time in interest-free installments with ###PAYMENT_TYPE###",
            moreInfo: "More info",
            modal: {
              title: "Paying in installments",
              subtitle: "Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free installments, with no hidden fees.",
              affirm: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments every two weeks with zero hidden fees. Payment options are offered by ###PAYMENT_TYPE###, are subject to an eligibility check, and may not be available in all states. CA residents: Loans by ###PAYMENT_TYPE### Loan Services, LLC are made or arranged pursuant to a California Finance Lender license.",
              afterpay: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              klarna: "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###"
            }
          },
          tooltip: "Ensure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store."
        }
      },
      price: {
        vatIncluded: "Tax included"
      },
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      frameSize: "Frame size",
      yourSelections: "Your selections",
      generic: {
        modal: {
          close: ""
        }
      }
    },
    en_AU: {
      steps: {
        advancedPrescription: {
          manual: {
            card: {
              description: 'This is our quickest and most popular method. We will then recommend the most suitable lens type.',
            },
            whatIsIt: 'What is it?',
            alerts: {
              axisRangeError: 'The AXIS values are incorrect. Please insert a value between 0 and 180.',
              sphPositiveNegativeWarning: 'You’ve inserted 1 positive and 1 negative value, which is rare. We ask that you would please double-check your prescription. If the value is correct, please proceed.',
              requiredToProceed: 'This is required to proceed',
              agreementText: 'Yes, my prescription is current, valid and matches the original copy.',
            },
            continueModal: {
              title: 'SEND PRESCRIPTION LATER',
              message: 'By clicking on continue, you will be skipping the prescription step. \n                We’ll ask you again during checkout, and after your order confirmation, you can either upload it or select it from my account.',
              messageNoAdd: 'Please make sure to enter the Addition (ADD) value if it is listed in your prescription, otherwise please proceed without.',
            },
          },
          later: {
            card: {
              description: 'We\'ll ask you again during checkout, and after your order confirmation, you can either upload it or select it from my account',
            },
          },
        },
        treatmentsFamily: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.',
        },
        thickness: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.',
        },
        treatments: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.',
        },
        transitionColor: {
          indoor: 'Indoor',
          outdoor: 'Outdoor',
        },
        color: {
          polarized: 'Polarised only',
          tooltip: {
            'switch': 'Reduces glare in extra bright light, for superior clarity and protection of the eye.',
            polarized: 'Reduces glare in extra bright light, for superior clarity and protection of the eye.',
            antiReflective: 'Reduce reflections and provides an invisible shield. It resists glare, dust and smudges for all round clearer vision.',
            uvProtection: 'Hard protective layers to the front and back of the lenses for maximum UV and scratch protection.',
          },
        },
        addOns: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.',
        },
        review: {
          subtitle: 'Your glasses will come with best in class Ray-Ban technology.',
          paymentInstallments: {
            installmentLabel: 'Or ###PAYMENT_TYPE### interest-free payments of ###MONTHLY_PRICE###',
            moreInfo: 'More info',
            modal: {
              title: 'Paying in installments',
              subtitle: 'Want to spread out the cost of your order with ###BRAND###? \n Select ###PAYMENT_TYPE### as your payment method at checkout \n to pay in interest-free installments, with no hidden fees.',
              affirm: 'With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments every two weeks with zero hidden fees. Payment options are offered by ###PAYMENT_TYPE###, are subject to an eligibility check, and may not be available in all states. CA residents: Loans by ###PAYMENT_TYPE### Loan Services, LLC are made or arranged pursuant to a California Finance Lender license.',
              afterpay: 'With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free payments, every two weeks. You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Click here for complete terms. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.',
            },
          },
        },
      },
      frameSize: 'Frame size',
    },
  },
};
