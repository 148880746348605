import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IncludedTreatment } from "../../components/IncludedBox/component";
import { StepCardProps } from "../../components/StepCard/component";
import { workflow } from "../models/Workflow";
import { useTranslation } from "../translations/TranslationHooks";
import { usePriceStepItem } from "./usePriceStepItem";

export function useReviewIncludedTreatmentsMapper() {
  const options = useSelector((state: any) => state.config.reviewOptions);
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  //const configPackages = useSelector((state: any) => state.config?.lensesData?.packages);
  const getPriceStep = usePriceStepItem();
  const translate = useTranslation();

  const [ret, setRet] = useState(null);
  const getOptP = (opt: string, treatmentName: string, currentPackages) => {
    //'antiReflective','blueLight','antiScratch','uvProtection'
    let ret: StepCardProps = {
      id: opt + "$" + treatmentName,
      title: treatmentName,
    };
    let priceObj = null;
    switch (opt) {
      case "antiScratch":
        priceObj = getPriceStep({ [opt]: true }, currentPackages);
        ret.title = translate(
          "steps.treatments.extraTreatments." + opt + ".title"
        );
        ret.imageUrl = translate(
          "steps.treatments.extraTreatments." + opt + ".imageFileName"
        );
        break;
      case "uvProtection":
        priceObj = getPriceStep({ [opt]: true }, currentPackages);
        ret.title = translate(
          "steps.treatments.extraTreatments." + opt + ".title"
        );
        ret.imageUrl = translate(
          "steps.treatments.extraTreatments." + opt + ".imageFileName"
        );
        break;
      case "blueLight":
        priceObj = getPriceStep({ [opt]: treatmentName }, currentPackages);
        ret.title =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].title
            : "";
        ret.description =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].description
            : "";
        ret.imageUrl =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].imageFileName
            : "";
        break;
      case "antiReflective":
        priceObj = getPriceStep({ [opt]: treatmentName }, currentPackages);
        ret.title =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].title
            : "";
        ret.description =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].description
            : "";
        ret.imageUrl =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].imageFileName
            : "";
        break;
      case "transition":
        priceObj = getPriceStep({ [opt]: treatmentName }, currentPackages);
        ret.title =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].title
            : "";
        ret.description =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].description
            : "";
        ret.imageUrl =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].imageFileName
            : "";
        break;
    }
    if (priceObj) {
      ret.price = priceObj.price;
      ret.discount = priceObj.discount;
      ret.insPrice = priceObj.insPrice;
    }
    return ret;
  };

  const getTreatments = (
    currentPackages: any[],
    empty?: boolean,
    forceTreatments?: string[]
  ) => {
    let included: IncludedTreatment[] = [];
    let treatments: IncludedTreatment[] = [];

    if (!currentPackages) {
      return { included, treatments };
    }

    options.forEach((opt: string) => {
      const packageWithTreatments = !empty
        ? currentPackages.filter((c) => c.lensPackage[opt])
        : currentPackages;
      const treatmentsOfPackets = groupBy(
        packageWithTreatments,
        "lensPackage." + opt
      );
      const isforced = forceTreatments && forceTreatments.indexOf(opt) >= 0;
      const allPackagesWithTreatment = currentPackages.every(
        (pack) => pack.lensPackage[opt]
      );
      if (treatmentsOfPackets && allPackagesWithTreatment) {
        const avaiableValue = Object.keys(treatmentsOfPackets);

        let selections = workflow.getAllCurrentParameterStep();
        let treatmentSelected = selections.find((sel) => sel.key === opt);

        if (avaiableValue.length <= 0 && !isforced) {
          //not found
        } else if (
          avaiableValue.length === 1 &&
          !isforced &&
          !treatmentSelected
        ) {
          //included
          if (opt === "antiReflective") {
            let addOnsSelection = selections.find(
              (sel) => sel.key === "addOns"
            );
            if (addOnsSelection) {
              if (
                addOnsSelection.attributes &&
                addOnsSelection.attributes.isPremium
              ) {
                //push the premium treatment
                const mapped = getOptP(opt, avaiableValue[0], currentPackages);
                treatments.push({
                  name: mapped.title,
                  id: opt,
                  key: mapped.id.split("$")[1],
                });

                //push the included standard treatment
                let temp = Object.keys(configContent.antiReflective);
                let nonPremiumValue = null;
                if (temp && temp.length) {
                  temp = temp.filter((val) => val !== avaiableValue[0]);
                  if (temp && temp.length) {
                    nonPremiumValue = temp[0];
                  }
                }

                if (nonPremiumValue) {
                  const mapped = getOptP(opt, nonPremiumValue, currentPackages);
                  included.push({
                    name: mapped.title,
                    id: opt,
                    key: mapped.id.split("$")[1],
                  });
                }
              } else if (
                addOnsSelection.attributes &&
                !addOnsSelection.attributes.isPremium
              ) {
                //push the standard treatment as included
                const mapped = getOptP(opt, avaiableValue[0], currentPackages);
                included.push({
                  name: mapped.title,
                  id: opt,
                  key: mapped.id.split("$")[1],
                });
              }
            } else {
              const mapped = getOptP(opt, avaiableValue[0], currentPackages);
              included.push({
                name: mapped.title,
                id: opt,
                key: mapped.id.split("$")[1],
              });
            }
          } else {
            const mapped = getOptP(opt, avaiableValue[0], currentPackages);
            included.push({
              name: mapped.title,
              id: opt,
              key: mapped.id.split("$")[1],
            });
          }
        }
      }
    });
    return { included, treatments };
  };
  useEffect(() => {
    setRet(
      () =>
        (currenPackages: any[], empty?: boolean, forceTreatments?: string[]) =>
          getTreatments(currenPackages, empty, forceTreatments)
    );
  }, [options, configContent, translate]);

  return ret;
}
